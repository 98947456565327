// Note: 維持 Key: [string]; 不換行, 方便語系比對閱讀
const notify_style = 'font-size: 24px; font-style: italic; font-weight: 500; color: #303133'

const tw = {
  /* project */
  system_name: 'Mobile AI Video Platform',
  system_language: '語言',
  system_timezone: '時區',
  system_default: '後台系統預設',

  /* global */
  yes: '是',
  no: '否',
  cancel: '取消',
  ok: '確定',
  save: '儲存',
  close: '關閉',
  delete: '刪除',
  confirm: '確認',
  finish: '完成',
  error: '錯誤',
  edit: '編輯',
  view: '檢視',
  none: '無',
  custom: '自訂',
  total: '共',
  loading: '讀取中',
  selected: '已選擇',
  search: '搜尋',
  pre: '上一步',
  next: '下一步',
  stop: '停用',
  unknown: '未知',
  tag: '標記',
  license_plate_example: 'ABC-1234',
  on: '開啟',
  off: '關閉',
  device: '設備',
  vehicle: '車輛',
  mission: '任務',
  name: '名稱',
  attribute: '屬性',
  file: '檔案',
  link: '勾稽',
  up_time: '開機時間',
  update_time: '更新時間',
  all: '全部',
  adv_search: '進階搜尋',
  search_result: '搜尋結果：{count}筆',
  new_tag_hint: '新標記',
  event: '事件',
  decibel_meter: '分貝計',
  hint: '提示',
  collapse: '收合',
  expand: '展開',
  search_fail_title: '搜尋失敗',
  no_subscription: '未登記',
  delete_data: '刪除資料',
  delete_data_confirm: '確定刪除 {count} 筆資料？',
  age: '{age}Y',
  upload: '上傳',
  uploading: '上傳中...',
  success: '成功',
  fail: '失敗',
  check: '檢查',
  create: '新增',
  show_all: '顯示全部',
  data_processing: '資料處理中...',
  data_loading: '載入中...',
  enable: '啟用',
  enabled: '啟用',
  disabled: '停用',
  search_placehold: '請選擇',
  nothing_modified: '未執行任何更改',
  no_data: '無資料',
  select_all: '全選',
  action: '動作',
  required: '必填',
  hub_reset: 'Hub 重置',
  reboot: '設備重啟',
  restart: '重啟',
  version: '版本',
  nothing_edit: '未有任何變更',
  update: '更新',
  status: '狀態',
  valid: '有效',
  invalid: '無效',
  format_invalid: '格式錯誤',
  empty_file: '{file} 不可為空檔案',
  error_code: '錯誤碼：{code}',

  /* alarm */
  data_error: '資料錯誤',

  /* language */
  lang_zh: '正體中文',
  lang_en: 'English',
  lang_ja: '日本語',

  /* week & time */
  sun: '日',
  mon: '一',
  tue: '二',
  wed: '三',
  thu: '四',
  fri: '五',
  sat: '六',
  month: '月',
  day: '天',
  one_day: '天',
  n_day: '{day} 天',
  hour: '小時',
  min: '分鐘',
  n_min: '{min} 分鐘',
  one_min: '分鐘',
  sec: '秒',
  n_sec: '{sec} 秒',
  month_ago: '個月前',
  day_ago: '天前',
  hour_ago: '小時前',
  min_ago: '分鐘前',
  sec_ago: '秒前',
  one_min_later: '分鐘後',
  min_later: '分鐘後',
  today: '今天',
  yesterday: '昨天',
  last_three_days: '過去3天',
  last_week: '過去1週',
  last_month: '過去1個月',
  last_three_months: '過去3個月',
  half_year_ago: '過去半年',
  custom_time: '自訂時間',
  parameter: '參數',

  /* login */
  login_id: '帳號',
  login_pw: '密碼',
  login_hint: '請輸入帳號及密碼',
  login_account_password_error: '帳號或密碼不正確',
  login_permission_hint: '該帳號沒有使用系統的權限，請洽管理員。',
  login_npa_hint: '請聯絡系統管理員',
  login_clean_cache_head: '資料處理',
  login_clean_cache_msg: '您確定要清除瀏覽器緩存內容嗎？',
  login_clean_cache_btn_tooltip: '清除緩存',

  /* Api */
  api_400: '缺少必要資訊',
  api_401: `帳號或密碼錯誤`,
  api_401_login: `帳號或密碼錯誤`,
  api_401_account_disabled: '該帳號已停用',
  api_401_account_suspended: '該帳號已鎖定，請聯絡系統管理員',
  api_403: `沒有權限`,
  api_403_duplicate_password: '該密碼已在最近 3 次內被使用',
  // api_403_certificate_key_pair_not_accepted: '憑證和密鑰不配對',
  api_403_certbot: `沒有權限（請檢查伺服器設定）`,
  api_404: `未提供資源`,
  api_404_login: `帳號或密碼錯誤`,
  api_404_default_setting: '無法取得系統預設參數',
  api_404_user_setting: '無法取得設備客製參數',
  api_406: `不可接受`,
  api_500: `伺服器錯誤`,
  api_error: `發生無法預期的錯誤，請聯絡系統管理員`, // Something Error
  api_econnaborted: '伺服器連線中斷。',
  api_timeout: '請求逾時。',
  api_get_title: '取得資料',
  api_network_error: '網路錯誤',

  /* left bar */
  left_bar_dashboard: '勤務派遣',
  left_bar_history: '歷史事件',
  // left_bar_subscription: '車號管理',
  left_bar_subscription: '比對資料庫',
  left_bar_recognition: '比對資料庫',
  left_bar_lpr_recognition: '車號管理',
  left_bar_fr_recognition: '人物管理',
  left_bar_account_management: '帳號與權限',
  left_bar_system_setting: '系統與服務',
  left_bar_log: '日誌',
  left_bar_download_app: '下載中心',
  left_bar_export_center: '匯出中心',
  left_bar_account: '我的帳號',
  left_bar_call: '語音通話',
  left_bar_joined_call: '已加入通話',
  left_bar_left_call: '已離開通話',
  left_bar_end_call_before: '請先結束目前的通話，再開啟新通話',
  left_bar_cant_call_myself: '無法撥號給自己',
  left_bar_15_seconds_hangup: '15秒無人接聽，自動掛斷通話',
  left_bar_failed_sdp_speak: '交換 SDP 資訊或發送語音通知失敗，將中斷通話連線',
  left_bar_another_webpage_call: '您已經在別的網頁開啟通話中，必須關閉才能重新開啟通話',
  left_bar_other_has_hungup: '對方已掛斷電話',
  left_ber_userinfo_change_password: '變更密碼',
  left_bar_userinfo_username_placeholder: '帳號名稱（限制{max}個字）',
  left_bar_userinfo_email_placeholder: '電子郵件',
  left_bar_userinfo_phone_placeholder: '電話號碼',
  left_ber_userinfo_change_password_sso_hint: 'SSO（單簽）帳號，不允許變更密碼',
  left_bar_userinfo_contact_us: '聯絡我們',
  left_bar_userinfo_line_official_account: '博遠智能官方LINE',

  /* top bar */
  top_bar_video_fill: '畫面填滿',
  top_bar_map_class: '地圖',
  top_bar_keep_login: '保持登入',
  top_bar_video_class: '影片',
  top_bar_online_devices: '上線設備',
  top_bar_show_full_title: '標題',
  top_bar_show_account_id: '帳號',
  top_bar_show_recog_frame: '直播辨識框',
  top_bar_calling_volume_off: '通話中，影片已靜音',
  top_bar_calling_volume_reverse: '通話結束，影片音量已恢復',

  /* 下載APP */
  download_notify_app: '通報APP',
  download_info: '請使用個人手機掃描二維條碼並下載通報APP',
  download_android: '安卓版',
  download_ios: 'iOS版',
  download_manual: '使用手冊',
  download_manual_fn: '使用手冊.pdf',
  download_tool: '工具',
  download_search_placeholder: '搜尋名稱',
  download_name: '名稱',
  download_version: '版本',
  download_update_time: '更新時間',
  download_size: '檔案大小',
  download_os: '作業系統',
  download: '下載',
  download_no_info: '無資料',

  /* 匯出中心 */
  export_fail: '無法匯出',
  export_fail_hint: '筆數超過 {count} 筆, 請縮小搜尋範圍',
  export_category: '類別',
  export_filename: '檔名',
  export_expiration_time: '過期時間',
  export_file_size: '檔案大小',
  export_status_download: '下載',
  export_status_fail: '匯出失敗',
  export_status_exporting: '匯出中',
  export_status_deleting: '刪除中',
  export_warning_title: '無法匯出資料',
  export_warning_content: '匯出中心已達5筆資料上限，請清除舊有資料。',
  export_warning_filename: '檔案名稱不得超過{count}個字元',
  export_result: '匯出結果',
  export_custom_filename: '檔案名稱',
  export_license_plate_picture: '匯出車牌圖片',
  export_snapshot: '匯出影像圖片',
  export_license_plate_picture_embedded: '報表內嵌車牌圖片',
  export_photo_warning: '筆數超過 {count} 筆，故無法匯出圖片，若欲匯出圖片，請縮小搜尋範圍',
  export_search_criteria: '搜尋條件',
  export_plate_number: '車號',
  export_time_interval: '時間區間',
  export_event_category: '事件類別',
  // export_mission: 'mission',
  export_finished_condition: '完成狀態',
  export_category_basic: '一般查詢',
  export_category_advanced: '進階查詢',
  export_category_performance: '績效查詢',
  export_category_audit: '稽核查詢',
  export_basic: '一般查詢',
  export_adv: '進階查詢',
  export_lpr_default_purpose: '業務管理',

  /* 二因子認證-2FA */
  twoFactorAuth_title: '雙重驗證（2FA）',
  twoFactorAuth_installApp: '使用驗證應用程式',
  twoFactorAuth_installApp_desc1: '使用 Google Authenticator 或 Duo Mobile 等應用程式來產生驗證碼，藉此加強防護。',
  twoFactorAuth_installApp_desc2: '如還未安裝驗證應用程式，請先進行安裝，以進行下一步。',
  twoFactorAuth_scanQrCode: '透過第三方驗證應用程式設定',
  twoFactorAuth_scanQrCode_desc: '請使用您的驗證應用程式（例如 Duo 或 Google Authenticator）來掃描 QR 碼。',
  twoFactorAuth_inputCode: '輸入確認碼',
  twoFactorAuth_inputCode_login: '輸入確認碼',
  twoFactorAuth_inputCode_desc: '請輸入驗證應用程式中的確認碼。',
  twoFactorAuth_done: '已啟用',
  twoFactorAuth_done_desc: '此帳號的雙重驗證（2FA）設定已啟用。',
  twoFactorAuth_enabled_pass: '啟用成功',
  twoFactorAuth_enabled_fail: '啟用失敗',
  twoFactorAuth_disabled_pass: '停用成功',
  twoFactorAuth_disabled_fail: '停用失敗',
  twoFactorAuth_validate_pass: '驗證成功',
  twoFactorAuth_validate_fail: '驗證失敗',
  twoFactorAuth_error_getQrCode: '取得 QR Code 失敗',
  twoFactorAuth_error_inputNumber: '請輸入數字',
  twoFactorAuth_error_fullCode: '請輸入完整驗證碼',

  /* 版本資訊 */
  version_info_header: '版本資訊',
  version_info_web: '網頁',
  version_info_version: '版本',
  version_info_core: '伺服器',
  version_info_version_range: '版本範圍',
  version_info_update_time: '更新時間',

  /* account */
  account_title: '帳號',
  account_name: '名稱',
  account_role: '角色',
  account_change_password: '重置密碼',
  account_device_change_password: '重置授權碼',
  account_disable_2fa: '重置雙重驗證（2FA）',
  account_id_tip: '請輸入{min}-{max}個字元，允許字母、數字或任一特殊符號',
  account_id_length_error: '帳號長度需{min}-{max}個字元',
  account_id_char_error: '帳號只允許英數，大小寫，及特殊字元',
  account_edit_password: '修改密碼',
  account_device_edit_password: '修改授權碼',
  account_edit_password_tip1: '1.長度需{min}～{max}個字元',
  account_edit_password_tip2: '2.請輸入任一特殊字元',
  account_pw_tip: '請輸入{min}-{max}個字元，包含字母、數字與任一特殊符號',
  account_new_pw: '新密碼',
  account_device_new_pw: '新授權碼',
  account_reenter_pw: '再次輸入您的新密碼',
  account_device_reenter_pw: '再次輸入您的新授權碼',
  account_pw_length_error: '長度需{min}～{max}個字元',
  account_pw_char_error: '只允許英數，大小寫，及特殊字元',
  account_pw_special_char_error: '須包含任一特殊字元',
  account_pw_error: '兩個密碼不一致，請確認！',
  account_device_pw_error: '兩個授權碼不一致，請確認！',
  account_pw_null: '不能是空值！',
  account_pw_success: '修改成功',
  account_pw_failed: '修改失敗',
  account_2fa: '雙重驗證（2FA）',
  account_logout: '登出',
  account_logout_hint: '你確定要離開嗎？',
  account_delete_confirm: '確定刪除',
  account_notify_edit_title: '修改帳號資料',
  account_notify_edit_success: '已成功修改帳號資料',
  account_notify_edit_failed: '修改帳號發生錯誤',
  account_search_role: '搜尋角色',
  account_add_role: '新增角色',
  account_edit_role: '編輯角色',
  account_copy_role: '複製角色',
  account_delete_role: '刪除角色',
  account_add: '新增帳號',
  account_add_success: '已成功新增帳號',
  account_add_failed: '新增帳號發生錯誤',
  account_add_failed_account_exist: '帳號已存在，請設定其他帳號',
  account_required: '<span class="required">*</span> 為必填項目',
  account_detail_group_accound: '群組與帳號',
  account_detail_group: '群組',
  account_detail_id: '帳號',
  account_detail_password: '密碼',
  account_device_detail_password: '授權碼',
  account_required_info: '此為必填',
  account_viewing_quota: '收看配額',
  account_multi_select: '多選帳號',
  account_cancel_multi_select: '取消多選模式',
  account_selected: '已選帳號',
  account_batch_selected_account_only: '顯示勾選帳號({count})',
  account_batch_edit_items: '批次修改項目',
  account_add_items: '新增項目',
  account_abnormal_account: '異常帳號',
  account_filter_result: '數量：{count}',
  account_batch_modified: '已批次修改',
  account_batch_n: '{pass_count} 筆成功，{fail_count} 筆失敗。',
  // account_n_success: '筆成功',
  // account_n_failed: '筆失敗',
  account_active: '啟用中',
  account_not_activated: '停用',
  account_enabled: '啟用',
  // account_disabled_until: '暫時停用至',
  account_disabled: '停用',
  account_suspend_view: '停權至 {time}',
  account_suspend_edit: '停權時間',
  account_status: '啟用狀態',
  account_status_enabled: '啟用',
  account_status_disable: '停用',
  account_status_locked: '停權',
  account_enable_confirm_title: '啟用帳號',
  account_enable_confirm_msg: '您確定要啟用帳號 {name} 嗎?',
  account_disable_confirm_title: '停用帳號',
  account_disable_confirm_msg: '您確定要停用帳號 {name} 嗎?',
  account_group: '群組',
  account_id: '帳號',
  account_password: '密碼',
  account_device_password: '授權碼',
  account_confirm_password: '確認密碼',
  account_device_confirm_password: '確認授權碼',
  account_detail_name: '名稱',
  account_all: '全部',
  account_public_title: '公開狀態',
  account_public_all: '全部',
  account_public_0: '私有',
  account_public_1: '公開',
  account_default_setting: '參數來源',
  account_defaultSetting_1: '系統預設',
  account_defaultSetting_0: '設備客製',
  account_watermark: '浮水印',
  account_watermark_0: '停用',
  account_watermark_1: '啟用',
  account_detail_linkmode_title: '勾稽',
  account_linkmode_title: '勾稽模式',
  account_linkmode_all: '全部',
  account_linkmode_0: '停用',
  account_linkmode_1: 'BoviLive',
  account_linkmode_2: 'BoviLive - 強制',
  account_linkmode_3: ' NPA',
  account_linkmode_4: 'NPA - 強制',
  account_linkmode_group: '群組',
  account_linkmode_user: '使用者',
  account_type: '帳號類型',
  account_kind_default: '預設',
  account_kind_sso: 'SSO(單簽)',
  account_kind_device: '設備',
  account_kind_user: '使用者',
  account_kind_0: '預設',
  account_kind_1: 'SSO(單簽)',
  account_kind_2: '設備',
  account_kind_3: '使用者',
  account_label: '帳號名稱',
  account_lang: '語系',
  account_tz: '時區',
  account_video_title: '影片標題',
  account_detail_property: '屬性',
  account_lpr_keep: '事件保留',
  account_video_keep: '影片保留',
  account_keep_day: '保留天數',
  account_keep_day_not_keep: '不保留',
  account_keep_day_by_disk: '依磁碟空間',
  account_video_keep_day: '影片',
  account_fr_keep_day: '人物',
  account_lpr_keep_day: '車牌',
  account_or_keep_day: '物件',
  account_device_model: '設備類型',
  account_device_model_0: '其他',
  account_device_series_10: 'BoviCam 系列',
  account_device_series_100: 'AiCam 系列',
  account_device_series_200: 'Bodyworn Cam 系列',
  account_device_series_500: 'FaceArk 系列',
  account_device_series_1000: 'Android APP 系列',
  account_device_series_2000: 'iOS APP 系列',
  account_device_series_3000: 'Mac 系列',
  // account_device_series_4000: 'VirtualCam 系列',
  account_ip_source: 'IP來源',
  account_gps: 'GPS',
  account_from: '帳號來源',
  account_last_action: '最新活動紀錄',
  account_note: '備註',
  account_time: '時間',
  account_login_time: '時間',
  // account_keep_day: '資料',
  account_updated_time: '更新時間',
  account_updatedTime_title: '更新時間',
  account_register_time: '建立時間',
  account_locked: '帳號已上鎖',
  account_15min_later: '15分鐘後',
  account_30min_later: '30分鐘後',
  account_1hr_later: '1小時後',
  account_edit_title: '編輯帳號',
  account_edit_device_model_hint: '請注意, 變更設備類型時會一併清空既存設備參數及事件接收人清單',
  account_edit_failed: '編輯帳號資料失敗',
  account_edit_success: '已成功編輯 {name}',
  account_lock_success: '已成功上鎖 {name}',
  account_lock_failed: '上鎖發生錯誤',
  account_unlock: '解除上鎖',
  account_unlock_success: '已成功解除上鎖 {name}',
  account_unlock_failed: '解除上鎖發生錯誤',
  account_unlock_confirm_title: '解鎖帳號',
  account_unlock_confirm_msg: '您確定要解鎖帳號 {name} 嗎?',
  account_source_sso: 'SSO（單簽）',
  account_source_self: '自建',
  account_forced_2fa: '雙重驗證',
  account_forced2fa_0: '按需',
  account_forced2fa_1: '強制',
  account_enabled_2fa: '已啟用雙重驗證',
  account_not_lived: '非直播設備',
  account_change_password_sso_hint: 'SSO（單簽）帳號，不允許重置密碼',
  account_forced_2fa_hint: '未強制使用雙重驗證',
  account_note_park_direction: '出入方向',
  account_note_park_direction_0: '入廠',
  account_note_park_direction_1: '出廠',
  account_note_park_direction_2: '無',
  account_note_park_vehicle_type: '車種',
  account_note_park_vehicle_type_3: '汽車',
  account_note_park_vehicle_type_4: '機車',
  account_lastLogin_title: '閒置時間',
  account_lastLogin_all: '全部',
  account_lastLogin_30: '30 天以上',
  account_lastLogin_90: '90 天以上',
  account_lastLogin_180: '180 天以上',

  /* role permission */
  // role_no_permission: '無權限',
  // role_general: '一般',
  // role_advanced: '進階',
  // role_medium: '中等',
  // role_higher: '高等',
  // role_off: '關',
  // role_on: '開',
  role_L0: 'L0：無權限。',
  role_new_name: '新角色（1）',
  role_topScope: '可視範圍',
  role_topScope_tooltip: '選一個群組做為可視範圍',
  role_topScop_default: '預設',
  role_topScope_desc1: '提供設置資源存取範圍。',
  role_topScope_desc2: '群組為樹狀架構，設置『可視範圍』可調整存取範圍之最高階群組節點，當前角色可存取該群組與其所有下階群組的所屬資源。',
  role_topScope_desc3: '非公開帳號產生之衍生資源均歸於所屬群組。',
  role_topScope_desc4: '無特別註明時，預設存取範圍=『可視範圍』',
  role_topScope_L0: '預設：帳號所屬群組。',
  role_topScope_L1: '指定：指定群組。',
  role_audit: '使用紀錄',
  role_audit_tooltip1: '關：只可查詢自己的紀錄',
  role_audit_tooltip2: '開：可查詢其他人的紀錄',
  role_audit_desc: '提供存取帳號日誌紀錄。',
  role_audit_L0: 'L0：可查詢該帳號自身使用紀錄。',
  role_audit_L1: 'L1：可存取使用紀錄。',
  role_mobilePermission: 'APP 存取',
  role_boviliveMobile: 'BoviLive Mobile',
  role_boviliveMobile_desc: 'BoviLive Mobile app 操控功能。',
  role_boviliveMobile_L1: 'L1：可依權限操控 app。',
  // 勤務派遣
  role_dashboardPermission: '勤務派遣',
  role_dashboard: '勤務派遣',
  role_dashboard_tooltip: '提供存取勤務派遣功能，用以統合掌控現場事件與狀態',
  role_dashboard_desc: '提供存取勤務派遣功能，用以統合掌控現場事件與狀態。',
  role_dashboard_L0: 'L0：無權限。無法存取勤務派遣功能頁。',
  role_dashboard_L1: 'L1：可存取勤務派遣。',
  role_dashboard_L2: 'L2：可存取公開設備列表。',
  // 影像管理
  role_videoPermission: '影像管理',
  role_liveView: '即時影音',
  role_liveView_tooltip1: '一般：可觀看即時影音',
  role_liveView_tooltip2: '進階：可觀看即時影音、修改標題、控制PTZ等功能',
  role_liveView_desc: '提供存取現場影像、聲音、GPS等資訊。',
  role_liveView_L1: 'L1：可存取即時影音。',
  role_liveView_L2: 'L2：可存取公開即時影音。',
  role_video: '歷史影音',
  role_video_title: '提供事後影像調閱、回放與下載',
  role_video_desc: '提供事後影像調閱、回放與下載。',
  role_video_L1: 'L1：可存取歷史影音。',
  role_video_L2: 'L2：可存取公開歷史影音。',
  role_videoManagement: '影片管理',
  role_videoManagement_tooltip: '提供歷史影像管理功能',
  role_videoManagement_desc: '提供歷史影像管理功能。',
  role_videoManagement_L1: 'L1：可修改歷史影音描述、保留/刪除影片。',
  // 設備操控
  role_deviceCtrlPermission: '設備操控',
  role_deviceCtrl: '設備操控',
  role_deviceCtrl_tooltip: '提供設備設定與操控功能',
  role_deviceCtrl_desc: '提供設備設定與操控功能。',
  role_deviceCtrl_L1: 'L1：可修改關於設備的描述，如影片標題。',
  role_deviceCtrl_L2: 'L2：可調整攝影機相關設定、PTZ控制、品質設置等。',
  role_deviceCtrl_L3: 'L3：可設置設備進階參數。',
  role_deviceAISetting: '辨識任務',
  role_deviceAISetting_title: '提供設備辨識任務設定功能',
  role_deviceAISetting_desc: '提供設備辨識任務設定功能。',
  role_deviceAISetting_L1: 'L1：可存取辨識任務設定。',
  // 語音通話
  role_voiceCallPermission: '語音通話',
  role_voiceCall: '語音對講',
  role_voiceCall_tooltip: '可使用語音通話功能',
  role_voiceCall_desc: '提供存取語音對講服務，包含1對1以及群呼模式。',
  role_voiceCall_L1: 'L1：可存取語音對講，包含1對1以及群組通話。',
  role_voiceCall_L2: 'L2：可存取公開通訊錄(使用者/設備)。',
  // 車辨事件
  role_lprEventPermission: '車辨事件',
  // role_lprDashboard: '儀表板功能',
  role_lprDashboard: '即時車牌事件',
  role_lprDashboard_tooltip1: '一般：儀表板可顯示 比對/任務/圍捕 事件',
  role_lprDashboard_tooltip2: '進階：儀表板可顯示所有事件',
  role_lprDashboard_desc: '提供即時車牌事件存取功能。',
  role_lprDashboard_L1: 'L1：可存取車牌即時比對符合事件（包含車輛資訊、任務、訂閱等）。',
  role_lprDashboard_L2: 'L2：可存取公開車牌即時比對符合事件（包含車輛資訊、任務、訂閱等）。',
  role_lprDashboard_L3: 'L3：可存取全部車牌即時事件。',
  // role_lprEventAccess: '事件查詢',
  role_lprEventAccess: '歷史車牌事件 - 一般',
  role_lprEventAccess_tooltip1: '可查詢單一車號的事件',
  role_lprEventAccess_desc: '提供歷史車牌事件查詢功能。',
  role_lprEventAccess_L1: 'L1：可操作一般查詢（僅公開設備）。',
  role_lprEventAdvancedAccess: '歷史車牌事件 - 進階',
  role_lprEventAdvancedAccess_tooltip1: '可使用進階條件查詢事件',
  role_lprEventAdvancedAccess_desc: '提供歷史車牌事件查詢功能。',
  role_lprEventAdvancedAccess_L1: 'L1：可操作進階查詢（僅私有設備）。',

  // 車號管理
  role_lprPermission: '車號管理',
  // role_lprSummary: '一般查詢',
  role_lprSummary: '車號查詢',
  role_lprSummary_tooltip: '可查詢單一車輛資料',
  role_lprSummary_desc: '提供比對資料庫之車號查詢功能，包含車輛資訊/任務/訂閱等。',
  role_lprSummary_L1: 'L1：可查詢全域性車號資料。',
  role_lprSummary_L2: 'L2：可查詢群組車輛資料。',
  role_lprSubscription: '訂閱管理',
  role_lprSubscription_tooltip: '可 新增/查詢/刪除 訂閱內容',
  role_lprSubscription_desc1: '提供訂閱車號管理功能。',
  role_lprSubscription_desc2: '訂閱為群組資料。',
  role_lprSubscription_desc3: '當前端設備辨識到此訂閱車號時, 不會示警，但系統會立即推播訊息至接收人手機所安裝的通報APP。',
  role_lprSubscription_L1: 'L1：可管理訂閱車號。',
  role_lprMissionLv: '任務管理',
  role_lprMissionLv_tooltip1: '一般：可設定一般任務（協尋）',
  role_lprMissionLv_tooltip2: '中等：可設定中等以下任務（盤查/協尋）',
  role_lprMissionLv_tooltip3: '高等：可設定高等以下任務（逮補/盤查/協尋）',
  role_lprMissionLv_desc1: '提供任務車號管理功能。',
  role_lprMissionLv_desc2: '任務為全域性資料。',
  role_lprMissionLv_desc3: '當前端設備辨識到此任務車號時，會立即示警並顯示請求協助的推送訊息 ， 並同時推播至接收人手機所安裝的通報APP。',
  role_lprMissionLv_L1: 'L1：可管理（協尋）任務。',
  role_lprMissionLv_L2: 'L2：可管理（協尋/盤查）任務。',
  role_lprMissionLv_L3: 'L3：可管理（協尋/盤查/逮捕）任務。',
  role_lprMission: '任務類型',
  role_lprMission_title: '（預留，目前未使用）',
  role_lprMission_desc: '',
  role_lprMission_L1: 'L1：可管理任務類型。',
  // role_lprInfoLv: '車輛資訊管理',
  role_lprInfoLv: '車輛資訊',
  role_lprInfoLv_tooltip: '可管理車輛標記與備註',
  role_lprInfoLv_desc1: '提供車輛資訊管理功能。',
  role_lprInfoLv_desc2: '車輛資訊為全域性資料。',
  role_lprInfoLv_L1: 'L1：可管理全域車輛資訊。',
  // role_lprInfo: '標記列表管理',
  role_lprInfo: '車輛標記',
  role_lprInfo_tooltip: '可管理標記列表',
  role_lprInfo_desc1: '提供車輛標記管理功能。',
  role_lprInfo_desc2: '車輛標記為全域性資料，做為車輛資訊之分類參考。',
  role_lprInfo_desc3: '單一車輛資訊可設置多重車輛標記。',
  role_lprInfo_L1: 'L1：可管理全域車輛標記。',
  // role_lprAnnouncement: '公告管理',
  role_lprAnnouncement: '推送公告',
  role_lprAnnouncement_tooltip: '可管理公告內容',
  role_lprAnnouncement_desc1: '提供推送公告管理功能。',
  role_lprAnnouncement_desc2: '推送公告為全域性資料。',
  role_lprAnnouncement_desc3: '公告訊息推送至前端設備，並以語音播放 1 次 ，輔以文字訊息捲動持續顯示。',
  role_lprAnnouncement_L0: 'L0：可檢視全域推送公告。',
  role_lprAnnouncement_L1: 'L1：可管理全域推送公告。',
  role_lprReport: '績效報表',
  role_lprReport_tooltip: '提供績效報表管理功能',
  role_lprReport_desc1: '- 自定義：依據擇定之日期與設備，產生客製績效報表。',
  role_lprReport_desc2: '- 定期：依據設置之匯出設備清單，每月自動產出系統績效報表。 ',
  role_lprReport_L1: 'L1：可存取自定義績效報表。',
  role_lprReport_L2: 'L2：可存取系統定期績效報表及管理匯出設備。',
  // 物件辨識
  role_orEventPermission: '物件辨識',
  role_orDashboard: '即時物件事件',
  role_orDashboard_desc: '提供即時物件事件存取功能。',
  role_orDashboard_L1: 'L1：可存取物件即時事件。',
  role_orDashboard_L2: 'L2：可存取公開物件即時事件。',
  role_orEventAccess: '歷史物件事件',
  role_orEventAccess_desc: '提供歷史物件事件存取功能。',
  role_orEventAccess_L1: 'L1：可存取物件歷史事件。',
  // 人臉辨識
  role_frEventPermission: '人臉辨識',
  role_frDashboard: '即時人物事件',
  role_frDashboard_desc: '提供即時人物事件存取功能。',
  role_frDashboard_L1: 'L1：可存取人物即時比對符合事件。',
  role_frDashboard_L2: 'L2：可存取公開人物即時比對符合事件。',
  role_frDashboard_L3: 'L3：可存取全部人物即時事件。',
  role_frEventAccess: '歷史人物事件',
  role_frEventAccess_desc: '提供歷史人物事件存取功能。',
  role_frEventAccess_L1: 'L1：可存取人物歷史事件。',
  // 人物管理
  role_frPermission: '人物管理',
  role_frInfoLv: '人物資訊',
  role_frInfoLv_desc1: '提供人物資訊管理功能。',
  role_frInfoLv_desc2: '人物資訊為全域性資料。',
  role_frInfoLv_L1: 'L1：可管理全域人物資訊。',
  role_frInfo: '人物標記',
  role_frInfo_desc1: '提供人物標記管理功能。',
  role_frInfo_desc2: '人物標記為全域性資料，做為車輛資訊之分類參考。',
  role_frInfo_desc3: '單一人物資訊可設置多重人物標記。',
  role_frInfo_L1: 'L1：可管理全域人臉標記。',
  // 帳號與權限
  role_accounrPermission: '帳號與權限',
  // role_account: '帳號管理',
  role_account: '使用者',
  role_account_tooltip: '可管理使用者帳號相關設定',
  role_account_desc1: '提供使用者帳號管理功能。',
  role_account_desc2: '使用者帳號需通過驗證，依據配置之角色權限決定各項資源與功能的存取層級。',
  role_account_L1: 'L1：可檢視使用者帳號。',
  role_account_L2: 'L2：可管理使用者帳號。',
  role_device: '設備',
  role_device_tooltip: '可管理設備帳號相關設定',
  role_device_desc1: '提供設備帳號管理功能。',
  role_device_desc2: '設備帳號需通過驗證，依據配置之授權決定可提供之功能與資源保留條件。',
  role_device_L1: 'L1：可檢視設備帳號。',
  role_device_L2: 'L2：可管理設備帳號。',
  role_device_L3: 'L3：可配置設備授權。',
  role_device_L4: 'L4：可新增設備帳號。',
  // role_group: '群組管理',
  role_group: '群組',
  role_group_tooltip: '可管理群組相關設定',
  role_group_desc1: '提供群組管理功能。',
  role_group_desc2: '群組為資源歸屬的基本單位，可配置使用者與設備，而其所衍生資源（如歷史影片、事件、日誌等）預設歸屬於該群組。',
  role_group_desc3: '群組不可刪除。',
  role_group_L1: 'L1：可檢視群組。',
  role_group_L2: 'L2：可管理群組。',
  // role_roleManagement: '角色管理',
  role_roleManagement: '角色',
  role_roleManagement_tooltip: '可管理角色',
  role_roleManagement_desc1: '提供角色管理功能。',
  role_roleManagement_desc2: '『角色(Role)』為權限的集合，定義各項系統資源的存取範圍（Scope）以及功能層級（Level）。',
  role_roleManagement_desc3: '具備 角色管理 權限的操作者，可建立或管理對應存取範圍或權限層級的角色。',
  role_roleManagement_desc4: '角色屬於全域，不可重名。',
  role_roleManagement_L1: 'L1：可檢視角色。',
  role_roleManagement_L2: 'L2：可管理角色。',
  // 系統與服務
  role_systemAndServicePermission: '系統與服務',
  // role_system: '系統管理',
  role_system: '系統設定',
  // role_system_tooltip: '可修改系統參數',
  role_system_desc1: '提供系統設定管理功能。',
  role_system_desc2: '用以調整系統偏好以及客製設定。',
  role_system_L1: 'L1：可管理一般系統設定。',
  role_system_L2: 'L2：可管理進階系統設定。(如：客戶，功能，辨識資料庫等管理)',

  role_systemDevice: '設備',
  role_systemDevice_desc1: '提供設備管理功能。',
  role_systemDevice_desc2: '用以檢視狀態、管理設備設定及服務。',
  role_systemDevice_L1: 'L1：可檢視設備設定。',
  role_systemDevice_L2: 'L2：可管理設備設定。',

  role_systemAibox: '辨識盒',
  role_systemAibox_desc1: '提供辨識盒管理功能。',
  role_systemAibox_desc2: '用以檢視狀態、管理服務及辨識任務。',
  role_systemAibox_L1: 'L1：可檢視辨識盒。',
  role_systemAibox_L2: 'L2：可管理辨識盒。',

  role_systemServer: '伺服器',
  role_systemServer_desc1: '提供伺服器管理功能。',
  role_systemServer_desc2: '用以檢視狀態、管理伺服器及服務。',
  role_systemServer_L1: 'L1：可檢視伺服器狀態。',
  role_systemServer_L2: 'L2：可管理伺服器。',

  role_systemCertificate: '憑證',
  role_systemCertificate_desc1: '提供憑證管理功能。',
  role_systemCertificate_desc2: '用以檢視與管理憑證。',
  role_systemCertificate_L1: 'L1：可檢視憑證。',
  role_systemCertificate_L2: 'L2：可管理憑證。',

  /* Role Notify / Message */
  role_notify_add_success: `已成功新增角色`,
  role_notify_add_info: `新增角色：<span style="${notify_style}">{name}</span>`,
  role_notify_add_fail: `無法新增角色`,
  role_notify_edit: '編輯角色',
  role_notify_edit_success: '已編輯角色',
  role_notify_delete_confirm: '確定刪除 <span>{name}</span> ？',
  role_notify_delete_success_title: '已成功刪除角色',
  role_notify_delete_success_msg: `刪除角色：<span style="${notify_style}">{name}</span>`,
  role_message_error_name_title: '無法修改名稱',
  role_message_error_name_content: '此名稱已被使用，請輸入其他名稱。',
  role_message_error_delete_title: '無法刪除角色',
  role_message_error_delete_prefix: '請先轉移',
  role_message_error_delete_suffix: '底下的所有帳號至其他角色',
  role_message_error_delete_transfer: '請先轉移 <span>{name}</span> 底下的所有使用者至其他角色。',

  /* 音效提示 */
  alarm_hint: '提示',
  alarm_announcement: '公告',
  alarm_info: '允許瀏覽器播放音效?',
  resolution_suggestion: `最佳檢視解析度：{resolution}`,
  // resolution_situation: `目前使用之瀏覽解析度 / 像素比：{w} x {h} / {p}%`,
  resolution_situation: `目前螢幕解析度（像素比）：{resolution}（{p}%）`,

  /* Dashboard: user tree */
  tree_watch_list: '關注清單',
  tree_device_list: '設備清單',
  tree_device_snapshot: '圖片模式',
  tree_device_hint: '請勾選欲關注設備',
  tree_search: '搜尋群組 / 設備',
  tree_online: '上線',
  tree_online_all: '上線 / 全部',
  tree_selected: '已勾選',
  tree_select_group: '只勾選此群組',
  tree_change_name: '修改名稱',
  tree_change_video_title: '修改影片標題',
  tree_change_group_name: '修改群組名稱',
  tree_push_talk: '群組通話',
  tree_select_account: '只勾選此設備',
  tree_voice_call: '一對一通話',
  tree_account_info: '帳號資訊',
  tree_view_device: '檢視設備',
  tree_view_user: '檢視使用者',
  tree_view_account: '檢視帳號',
  tree_video_export: '影片匯出',
  tree_processing_info: '處理中...',
  tree_no_data: '無資料',
  tree_contacts: '通訊錄',
  tree_please_click: '請點擊',
  tree_tip: '勾選欲關注設備',
  tree_call_search: '搜尋群組 / 帳號',

  /* Dashboard: video download */
  video_start_time: '開始時間',
  video_end_time: '結束時間',
  video_type: '影片類型',
  video_length: '影片長度',
  video_size: '影片大小',
  video_date_placeholder: '請選擇起迄時間',
  video_select_all: '選取全部',
  video_unselect: '取消保留',
  video_keep_all: '全部保留',
  video_keep_select: '保留勾選',
  video_download_select: '下載勾選',
  video_delete_select: '刪除勾選',
  video_download: '下載影片',
  video_keep: '保留',
  video_none_delete: '無影片可刪除！',
  video_delete: '影片已刪除！',
  video_none_select: '無勾選資料！',
  video_set_keep: '影片已設定保留！',
  video_no_keep: '影片已取消保留！',
  video_delete_data: '刪除資料',
  video_delete_checked: '確定要刪除已勾選的影片嗎？',
  video_selected: '已勾選',
  video_total_of: '共',
  video_of_records: '筆資料',
  video_unsupported_mime_codec: '不支援的影片格式',
  video_play_error: '影片無法播放',

  /* Video Control Button */
  video_ctrlbtn_ptz: '遠端鏡頭控制',
  video_ctrlbtn_time_axis: '歷史影音',
  video_search_none: '查無歷史影片！',

  /* Dashboard: event cards */
  event_tab_all: '全部',
  event_tab_match: '比對',
  event_tab_urgent: '緊急',
  event_tab_video: '影片',
  event_show_position: '顯示帳號位置',
  evnet_show_history: '顯示相關歷史紀錄',
  event_source: '來源',
  event_result: '比對結果',
  event_note: '備註',
  event_recg_note: '辨識備註',
  event_emergency: '緊急事件',
  event_switch: '顯示所有事件',
  event_audio: '比對事件音效',
  event_not_db: '非資料庫事件',
  event_chasing: '圍捕',
  event_sos: 'SOS',

  event_lpr: '車牌事件',
  event_fr: '人物事件',
  event_chased: '圍捕事件',
  event_sos_title: 'SOS事件',
  event_video: '影片事件',
  event_or: '物件事件',

  /* PTZ */
  ptz_move_speed: '移動速度',
  ptz_zoom: '變焦',
  ptz_focus: '對焦',
  ptz_preset: '點位清單',
  ptz_preset_placeholder: '新點位',
  ptz_preset_setting_success: '點位設定成功！',
  ptz_preset_setting_fail: '點位設定失敗！',
  ptz_vertical_mirror: '垂直鏡像',
  ptz_horizontal_mirror: '水平鏡像',
  ptz_infrared: '紅外線',
  ptz_high_sensitivity: '高感度',
  ptz_tooltip_zoom_out: '縮小',
  ptz_tooltip_zoom_in: '放大',
  ptz_tooltip_open: '開啟',
  ptz_tooltip_close: '關閉',
  ptz_tooltip_auto: '自動',
  ptz_tooltip_auto_focus: '自動對焦',
  ptz_tooltip_near_focus: '近焦',
  ptz_tooltip_far_focus: '遠焦',
  ptz_tooltip_gamepad: '接上搖桿後，請按下任意按鈕，以啟動搖桿操控功能。',

  /* 影像 */
  image: '影像',

  /* 設備 */
  device_reboot: '重啟設備',
  device_reboot_confirm: '確定要重啟設備 {account} 嗎？',
  device_hub_reset: '重置 Hub',
  device_hub_reset_confirm: '確定要重置設備 {account} 的 Hub 嗎？',

  /* History: Search Bar */
  search_tab_history: '一般查詢',
  search_tab_advanced_search: '進階查詢',
  search_cause: '案由（必填）',
  search_cause_placehold: '偵辦王小明毒品案',
  search_plate_number_required: '車號（必填）',
  search_time_interval: '時間區間',
  search_select_date: '選擇日期',
  search_select_time: '選擇時間',
  search_time_placehold: '請選擇起迄時間',
  search_hint: '提示',
  search_hint_reason: '請輸入案由',
  search_hint_reason_length: '案由需小於等於30個字',
  search_hint_fill_number: '請填入「車號」',
  search_hint_fill_device: '請選擇「設備」',
  search_hint_fill_time: '請選擇「時間區間」',
  search_hint_fill_operate_time: '請選擇「操作時間」',
  search_hint_nodata: '查無資料',
  search_export: '匯出',
  search_device: '設備',
  search_device_required: '設備（必填）',
  search_device_placehold: '請選擇',
  search_device_unit: '單位',
  search_plate_number: '車號',
  search_car_type: '車種',
  search_car: '汽車',
  search_motocycle: '機車',
  search_event_category: '事件類別',
  search_please_choose: '請選擇',
  search_all: '全部',
  search_subscription: '訂閱',
  search_mission: '任務',
  search_mark: '標記',
  search_round_up: '圍捕',
  search_not_specified: '未指定',
  search_misjudgment: '誤判',
  search_non_misjudgment: '非誤判',
  search_cofind: '協尋',
  search_check: '盤查',
  search_arrest: '逮捕',
  search_vehicle_marking: '車輛標記',
  search_finished_status: '完成狀態',
  search_finish: '完成',
  search_undone: '未完成',
  search_total_of: '共',
  search_of_events: '筆',
  search_timeout_message: '內容過多，請縮小查詢範圍',
  search_clear: '清除',
  search_adv_filter: '進階條件',
  search_channel_id: '鏡頭',
  search_matched: '比對符合',
  search_inference_matched: '推論符合',
  search_no_match: '比對不符合',
  search_cause_default_park: '歷史查詢',

  /* History: History Events */
  history_event: '歷史事件',
  history_event_lpr: '車牌',
  history_event_fr: '人物',
  history_event_or: '物件',
  history_event_previous: '上一筆',
  history_event_next: '下一筆',
  history_event_wheelzoom: '滾輪縮放',
  history_event_previous_tooltip: '上一筆（左鍵）',
  history_event_next_tooltip: '下一筆（右鍵）',
  history_event_back_tooltip: '關閉（Esc）',
  history_event_map_goto: '開啟Google map',
  history_query_by_event: '以事件查詢',
  history_device: '辨識設備',
  history_employee: '員工',
  history_recognition: '辨識',
  history_tag: '車輛標記',
  history_mission: '任務',
  history_mission_note: '任務備註',
  history_channel: '鏡頭',
  history_event_prev_page: '上一頁',
  history_event_next_page: '下一頁',
  history_event_no: '第',
  history_event_pages: '頁',
  history_event_screen_capture: '擷取畫面',
  history_event_screen_capture_info: '擷取尺寸與畫面尺寸不符，請重新載入網頁，再進行擷圖',
  history_show_snapshot: '顯示圖片',
  history_show_video: '顯示影片',

  /* History: 人物 */
  history_fr_name: '姓名',
  history_fr_id: '身份識別碼',
  history_fr_age: '年齡',
  history_fr_tag: '人物標記',
  history_fr_note: '人物備註',
  history_fr_group: '群組',
  history_fr_device: '設備',
  history_fr_gps: 'GPS',
  history_fr_open_google_map: '開啟Google map',
  history_fr_event_category: '事件類別',
  history_fr_score: '辨識分數',
  history_fr_cv: '信心值',
  history_fr_tag_suspected: '疑似目標人物',

  /* History: 緊急 */
  history_device_group: '設備群組',
  history_link_user: '勾稽使用者',
  history_user_group: '使用者群組',

  /* QR Code Panel */
  qr_title: '下載手機通報APP',

  /* Dashboard: tooltips */
  previous_video: '前一段影片',
  next_video: '後一段影片',
  play: '播放',
  pause: '暫停',
  mute: '靜音',
  unmute: '解除靜音',
  play_rate: '播放倍率',
  zoom_in: '放大畫面',
  zoom_out: '縮小畫面',
  zoom_ratio: '縮放倍率',
  show_only_events: '只顯示此設備的事件',
  download_video: '影片下載',
  full_screen: '全螢幕',
  jump_to_live: '跳至直播畫面',
  back_event: '返回事件',
  forward_15s: '前進15秒',
  backward_15s: '後退15秒',
  the_day_before: '前一天',
  the_day_after: '後一天',
  a_minute_ago: '前一分鐘',
  one_minute_after: '後一分鐘',
  zoom_out_the_play: '縮小播放視窗',
  enlarge_the_play: '放大播放視窗',
  mode_1x1: '1x1',
  mode_2x2: '2x2',
  mode_3x3: '3x3',
  mode_4x4: '4x4',
  mode_1_5: '1+5',
  mode_1_12: '1+12',
  mode_2_8: '2+8',
  reorder_device_list: '重新排序',
  collapse_the_play: '收合播放視窗',
  expand_the_play: '展開播放視窗',
  expand_event_list: '展開事件列表',
  collapse_event_list: '收合事件列表',
  more: '更多',
  less: '更少',
  automatic_logout: '防止閒置自動登出',
  no_selected_user: '未選擇設備',
  no_streaming: '無串流',
  no_permission_all: '無觀看即時影音與歷史影音權限',
  no_permission_live_view: '無觀看即時影音權限',
  no_permission_video: '無觀看歷史影音權限',
  no_live_stream: '無可播放的即時影音',
  no_video: '無可播放的歷史影音',
  no_video_within: '{hour} 小時內無可播放的歷史影音',
  video_pause: '停留在暫停畫面',
  mode_nxn_switch_hint: '請拖放有設備的視窗進行互換！',

  /* Account Management */
  am_title: '帳號與權限',
  am_user: '使用者',
  am_device: '設備',
  am_group: '群組',
  am_role: '角色',
  am_role_label: '角色',
  am_search_account: '搜尋帳號/帳號名稱',
  am_edit_not_complete: '編輯未完成',
  am_cancel_edit: '確定取消此編輯嗎？',
  am_map_search_hint: '搜尋路名或GPS',
  am_map_reset: '重置點位',
  am_user_create: '新增使用者',
  am_user_create_confirm: '使用者新增後將無法刪除，您確定要新增嗎？',

  /* Account Management-device */
  am_search_device: '搜尋設備/設備名稱/影片標題',
  am_device_name: '名稱',
  am_device_video_name: '影片標題',
  am_device_event_keep: '事件保留',
  am_device_video_keep: '影片保留',
  am_device_fr_event_keep: '人物事件保留',
  am_device_lpr_event_keep: '車牌事件保留',
  am_device_or_event_keep: '物件事件保留',
  am_device_video_keep_title: '影片',
  am_device_video_keep_tooltip: '影片保留（天）',
  am_device_fr_event_keep_title: '人物',
  am_device_fr_event_keep_tooltip: '人物保留（天）',
  am_device_lpr_event_keep_title: '車牌',
  am_device_lpr_event_keep_tooltip: '車牌保留（天）',
  am_device_or_event_keep_title: '物件',
  am_device_or_event_keep_tooltip: '物件保留（天）',
  am_group_tree_search: '搜尋群組',
  am_device_keep_time: '保留時間',
  am_device_keep_time_tip: '系統預設設定的保留時間。',
  am_device_keep_time_edit: '編輯保留時間',
  am_device_create: '新增設備',
  am_device_create_confirm: '設備新增後將無法刪除，您確定要新增嗎？',
  am_device_type_default: '一般',
  am_device_type_bwc: 'Bodycam',
  am_device_type_patrol: 'Patrol',
  am_device_type_phone: 'Phone',
  am_device_type_ptz: 'PTZ 球機',
  am_device_type_box: 'PTZ 箱機',

  /* Account Management-group */
  am_group_tab_group: '群組',
  am_group_tab_quota: '授權',
  am_search_group: '搜尋群組',
  am_group_count: '群組數量：{count}',
  am_group_online_account: '使用中帳號',
  am_group_name_top: '請輸入{min}-{max}個字元',
  am_group_title: '群組',
  am_view_quota_title: '收看配額',
  am_live_quota_title: '發布配額',
  am_group_quota_out: '收看',
  am_group_quota_in: '發布',
  an_group_quota_filter: '授權狀態',
  an_group_quota_filter_0: '無授權',
  an_group_quota_filter_1: '授權未滿',
  an_group_quota_filter_2: '授權已滿',
  am_quota_desc: '已使用 / 已分配 / 全部',
  am_quota_edit: '編輯授權',
  am_quota_editing: '編輯中',
  am_quota_edit_confirm: '您確定要修改授權嗎？',
  am_quota_using_value: '使用中',
  am_quota_divided_value: '已分配',
  am_quota_max_value: '授權數',
  am_quota_parent_group_auth_empty: '上層群組 {name} 未設定授權',
  am_quota_parent_group_auth_full: '上層群組 {name} 授權已滿',
  am_quota_auth_not_enough: '{name} 授權不足',
  am_quota_auth_full: '{name} 授權已分完, 無法刪減',
  // am_quota_error_group_has_account: '群組 {name} 尚有帳號, 故不能調整所屬群組',
  // am_quota_error_group_has_user: '群組 {name} 尚有使用者, 故不能調整所屬群組',
  am_quota_error_group_has_user: '無法調整群組',
  am_quota_error_group_has_conflict_user: '衝突使用者：{users}',
  // am_quota_error_group_has_both_quota: '群組 {name} 尚有發布及收看授權, 故不能調整所屬群組',
  // am_quota_error_group_has_in_quota: '群組 {name} 尚有發布授權, 故不能調整所屬群組',
  // am_quota_error_group_has_out_quota: '群組 {name} 尚有收看授權, 故不能調整所屬群組',
  am_quota_error_group_has_quota: '群組授權數在移動群組後將歸零，需重新分配授權',
  am_add_group: '新增群組',
  am_add_subgroup: '新增子群組',
  am_parent_group_empty: '所屬群組名稱',
  am_parent_group: '所屬群組',
  am_parent_group_hint: '所屬的群組',
  am_group_name: '群組名稱',
  am_group_name_hint: '新群組（1）',
  am_group_name_tip: '請輸入{min}-{max}個字元',
  am_group_name_duplicate: '此群組名稱已存在',
  am_group_id: 'ID',
  am_group_updated_time: '修改時間',
  am_group_create_time: '創建時間',
  am_add_group_fail: '新增群組發生錯誤',
  am_add_group_pass: '已成功新增群組',
  am_edit_group_fail: '修改群組發生錯誤',
  am_edit_group_pass: '已成功修改群組',
  am_quota_short_hint1: '群組',
  am_quota_short_hint2: '配額不足',
  am_group_create_confirm: '群組新增後將無法刪除，您確定要新增嗎？',
  am_group_department_code: '單位代碼',
  am_group_staff_info: '資訊',
  am_group_watching: '正在觀看',
  am_group_account_user: '使用者',
  am_group_account_device: '設備',
  am_group_quota_using: '使用中',
  am_group_quota_divided: '已分配',
  am_group_quota_total: '總分配',
  am_group_dev_info_snapshot_link_tooltop: '快照連結',
  am_group_dev_info_streaming_link_tooltop: '串流連結',
  am_group_dev_info_name: '名稱',
  am_group_dev_info_info_name: '設備名稱',
  am_group_dev_info_video_title: '影片標題',
  am_group_dev_info_property: '屬性',
  am_group_dev_info_device_id: '設備序號',
  am_group_dev_info_device_model_id: '設備類型',
  am_group_dev_info_device_info_type: '裝置類型',
  am_group_dev_info_latest_action: '最新活動紀錄',
  am_group_dev_info_gps: 'GPS',
  am_group_dev_info_gps_timestamp: '時間',
  am_group_duplicate_name: '此群組名稱已存在',
  am_group_edit_notify: '編輯群組',
  am_group_edit_confirm: '您確定要修改群組嗎？',
  am_group_add_notify: '新增群組',

  /* Account Management-role */
  am_role_helper_title: '角色管理',
  am_role_helper_intro: '「角色（Role）」為權限的集合，目的為定義各項系統資源的存取範圍以及功能層級。角色可被套用在使用者帳號上，藉此規範此帳號可以操作的權限層級。',
  am_role_helper_desc1: '高階層級包含低階層級的存取範圍以及功能項目。',
  am_role_helper_desc1_sub1: '存取範圍（Scope）：層級由低至高，範圍愈來愈大。',
  am_role_helper_desc1_sub2: '功能層級（Level）：層級由低至高，權限愈來愈多。',
  am_role_helper_desc2: '角色屬於全域，不可重名。',

  /* System Setting */
  // system_global_setting: '全域設定',
  // system_custom_web: '客製網頁',
  // system_backend_setting: '後台設定',
  // system_server_capability: '伺服器能力',
  system_setting: '設定',
  system_setting_general: '一般',
  system_setting_recognition: '辨識',
  system_setting_logo: '標誌',
  system_aibox: '辨識盒',
  system_server_state: '伺服器',
  system_cert: '憑證',
  system_custom_system_name: '系統名稱',
  system_custom_idle_logout_time: '閒置登出時間',
  system_custom_logout_not: '不登出',
  system_custom_app_download: '顯示APP下載連結',
  system_custom_manual_download: '顯示使用手冊載點',
  system_custom_customize: '客戶',
  system_custom_favicon: '頁籤上小圖 favicon 32*32',
  system_custom_banner: '左側欄展開 banner 130*56',
  system_custom_logo: '左側欄收合小圖 56*56',
  system_custom_upload: '上傳圖片',
  system_custom_restore: '還原預設',
  system_custom_restore_info: '將以上圖示設定還原為預設值',
  /* System Manage - System Setting (設定-一般) */
  system_custom_web_customize_func: '功能',
  system_custom_web_customize_func_park: '停車場系統',
  system_custom_application: '應用',
  system_custom_security: '安全性',
  system_custom_download: '下載',
  system_custom_location: '地區',
  /* System Manage - System Setting (設定-辨識) */
  system_manage_recognition_fr: '人物辨識',
  system_manage_recognition_fr_db: '資料庫',
  system_manage_recognition_fr_photo_count: '註冊圖限制張數',
  system_manage_photo_count: '{count} 張',
  system_manage_recognition_lpr: '車輛辨識',
  system_manage_recognition_lpr_sub_db: '訂閱資料庫',
  system_manage_recognition_lpr_mission_db: '任務資料庫',
  system_manage_recognition_lpr_tag_db: '標記資料庫',
  system_manage_recognition_lpr_region: '車牌顯示', // NOT DO
  system_manage_recognition_package_frequency: '打包頻率',
  system_manage_recognition_region: '區域',
  /* System Manage - System Setting (設定-標誌) */
  system_manage_logo_open_file: '開啟檔案',
  system_manage_logo_reset: '還原',
  system_manage_logo_favicon_title: 'Favicon',
  system_manage_logo_favicon_desc1: '瀏覽器籤上的小圖示。',
  system_manage_logo_favicon_desc2: '建議使用32X32像素的PNG檔。',
  system_manage_logo_login_title: '登入頁',
  system_manage_logo_login_desc1: '登入頁中央的大圖示，可套用橫式LOGO。',
  system_manage_logo_login_desc2: '建議使用130X56像素的SVG檔。',
  system_manage_logo_dashboard_logo_title: '網頁LOGO',
  system_manage_logo_dashboard_logo_desc1: '左側導航欄上的LOGO。',
  system_manage_logo_dashboard_logo_desc2: '建議使用56X56像素的SVG檔。',
  system_manage_logo_dashboard_banner_title: '勤務派遣橫幅',
  system_manage_logo_dashboard_banner_desc1: '位於勤務派遣頁面左上角的圖示，可套用橫式LOGO。',
  system_manage_logo_dashboard_banner_desc2: '建議使用130X56像素的SVG檔。',

  /* System Manage - Device */
  system_device: '設備',
  system_device_select_model_hint: '請先選擇左側 設備類型',
  system_device_tab_video: '影像',
  system_device_tab_param: '參數',
  system_device_tab_recognition: '辨識',

  /* System Manage - AiBoxMgr */
  setting_aibox_top_box: '辨識盒',
  setting_aibox_top_ip: 'IP',
  setting_aibox_top_count: '辨識盒數量',
  setting_aibox_top_abnormal_count: '異常數',
  setting_aibox_page_start: '第',
  setting_aibox_page_end: '筆',
  setting_aibox_page_total: '搜尋結果',
  setting_aibox_total_of: '資料庫共',
  setting_aibox_of_records: '筆資料',
  setting_aibox_goto_page: '跳頁',
  setting_aibox_state_normal: '正常',
  setting_aibox_state_abnormal: '異常',
  setting_aibox_table_name: '辨識盒',
  setting_aibox_table_ip: 'IP',
  setting_aibox_table_udid: 'UDID',
  setting_aibox_table_status: '任務',
  // setting_aibox_table_temp: '溫度',
  setting_aibox_table_cpu: 'CPU',
  setting_aibox_table_gpu: 'GPU',
  setting_aibox_table_mem: 'MEM',
  setting_aibox_table_hd: 'HDD',
  setting_aibox_table_recognition_src: '資源',
  setting_aibox_table_updated_time: '更新時間',
  setting_aibox_delete_title: '刪除資料',
  setting_aibox_task_device: '設備',
  setting_aibox_type_or: '物件辨識',
  setting_aibox_type_fr: '人物辨識',
  setting_aibox_type_lpr: '車牌辨識',
  setting_aibox_task_recognition_type: '辨識類別',
  setting_aibox_task_recognition_state: '辨識狀態',
  setting_aibox_not_connected: '辨識盒未連線',
  setting_aibox_task_status_0: '啟用',
  setting_aibox_task_status_1: '辨識中',
  setting_aibox_task_status_10: '設備未發布',
  setting_aibox_task_status_11: '串流連接中',
  setting_aibox_task_status_12: '授權數不足',
  setting_aibox_task_status_13: '系統性錯誤',
  setting_aibox_task_status_14: '設定檔版本不支援',
  setting_aibox_task_status_15: '辨識功能不支援',
  setting_aibox_task_status_16: '參數內容不符合',
  setting_aibox_task_recognition_or: '物件',
  setting_aibox_task_recognition_lpr: '車輛',
  setting_aibox_delete_aibox: '確定刪除此辨識盒嗎?',
  setting_aibox_delete_aibox_hint: '尚有辨識任務，無法刪除',
  setting_aibox_delete_notify_title: '刪除辨識盒',
  setting_aibox_delete_pass: '已成功刪除辨識盒',
  setting_aibox_delete_fail: '刪除辨識盒失敗',
  setting_aibox_delete_aibox_task: '確定刪除此設備的辨識任務嗎?',
  setting_aibox_task_delete_notify_title: '刪除辨識任務',
  setting_aibox_task_delete_pass: '已成功刪除辨識任務',
  setting_aibox_task_delete_fail: '刪除辨識任務失敗',

  /* System Manage - System Setting (設定) */
  system_manage_setting_save_toast: '修改資料',
  system_manage_setting_save_confirm_header: '系統設定',
  system_manage_setting_save_confirm_msg: '您確定要修改設定嗎？',
  system_manage_setting_leave_confirm_header: '取消編輯',
  system_manage_setting_leave_confirm_msg: '您確定要取消編輯嗎？',
  system_manage_setting_init: '初始化',
  system_manage_setting_init_toast: '初始化資料',

  /* System Manage - Server State (伺服器) */
  system_server_state_title_srv: '伺服器 / 服務',
  system_server_state_mgr_header: '{name} 伺服器管理',
  system_server_state_mgr_keep_space: '保留空間',
  system_server_state_mgr_save_toast: '設定伺服器',
  system_server_state_mgr_save_confirm_header: '設定伺服器',
  system_server_state_mgr_save_confirm_msg: '您確定要修改設定嗎？',
  system_server_state_mgr_restart_toast: '重啟伺服器',
  system_server_state_mgr_restart_confirm_header: '重啟伺服器',
  system_server_state_mgr_restart_confirm_msg: '您確定要重啟{name}伺服器嗎？',

  /* AI Box */
  ai_recognition_setting: '辨識設定',
  ai_device_recognition: '本機辨識',
  ai_person_recognition: '人物辨識',
  ai_could_recognition: '雲端辨識',
  ai_object_recognition: '物件辨識',
  ai_object: '物件',
  ai_license_plate_recognition: '車牌辨識',
  ai_license_plate: '車牌',
  ai_updated_time: '更新時間',
  ai_recognition: '辨識',
  ai_notify: '通報',
  ai_notify_setting: '通報設定',
  ai_box: '辨識盒',
  ai_task_add: '新增中',
  ai_task_edit: '編輯中',
  ai_task_delete: '刪除中',
  ai_status_0: '啟用',
  ai_status_1: '辨識中',
  ai_status_10: '設備未發布',
  ai_status_11: '串流連接中',
  ai_status_12: '授權數不足',
  ai_status_13: '系統性錯誤',
  ai_status_14: '設定檔版本不支援',
  ai_status_15: '辨識功能不支援',
  ai_status_16: '參數內容不符合',
  ai_try_connect: '嘗試連接串流',
  ai_select_aibox: '請選擇辨識盒',
  ai_temperature: '溫度',
  ai_memory: 'MEM',
  ai_hard_disk: 'HDD',
  ai_resources: '辨識資源',
  ai_other_resources: '其他辨識盒',
  ai_add_notification_task: '新增通報任務',
  ai_image_preview: '影像預覽',
  ai_recg_fps_level: '辨識頻率',
  ai_repeat_interval: '重複事件間隔',
  ai_stay_sec: '停留秒數',
  ai_category: '類別',
  ai_subscriber: '接收人',
  ai_group_name_placeholder: '群組 或 姓名',
  ai_show_checked: '顯示已勾選',
  ai_edit_receiver: '編輯接收人',
  ai_mode: '模式',
  ai_object_detect: '物件偵測',
  ai_motion_detect: '移動偵測',
  ai_detect_target_threshold: '偵測目標閾值',
  ai_motion_level: '偵測靈敏度',
  ai_advanced_settings: '專家模式',
  ai_person_level: '人',
  ai_car_level: '汽車',
  ai_bike_level: '二輪車',
  ai_truck_level: '卡車',
  ai_bus_level: '公車',
  ai_warning_area: '警戒區',
  ai_delete_data: '刪除資料',
  ai_device: '設備',
  ai_recognition_category: '辨識類別',
  ai_recognition_status: '辨識狀態',
  ai_level_0: '',
  ai_level_1: '最低',
  ai_level_2: '低',
  ai_level_3: '中',
  ai_level_4: '高',
  ai_level_5: '最高',
  ai_motion_level_1: '低',
  ai_motion_level_2: '中',
  ai_motion_level_3: '高',
  ai_insufficient_recognition_resources: '辨識資源不足',
  ai_no_boxes_available: '很抱歉，無可使用的辨識盒。',
  ai_edit_not_complete: '設定未儲存',
  ai_cancel_edit: '確定要取消嗎？',
  ai_parameter_adjustment: '參數調整',
  ai_event_score_greater_than: '事件觸發分數需大於等於事件候選分數',
  ai_candidate_score_adjust: '事件候選分數調整為 {score}',
  ai_event_score_adjust: '事件觸發分數調整為 {score}',
  ai_area1: '區域1',
  ai_area2: '區域2',
  ai_area3: '區域3',
  ai_new_recognition: '新增辨識任務',
  ai_recog_task_add_successfully: '辨識任務新增成功',
  ai_edit_recognition: '設定辨識參數',
  ai_recog_task_edit_successfully: '辨識參數設定成功',
  ai_task_already_added: '已有新增的辨識任務，請設定完成後再新增',
  ai_roi_set: '調整辨識範圍',
  ai_roi_lock: '鎖定辨識範圍',
  ai_tooltip_roi_reset: '恢復至預設範圍',
  ai_tooltip_mode: '<strong style="color: #FFE99F;">物件偵測</strong>: 偵測到所勾選的物件後發出通報<br/><strong style="color: #FFE99F;">移動偵測</strong>: 偵測到畫面中有移動物體後發出通報',
  ai_tooltip_or: '物件偵測：偵測到所勾選的物件後發出通報',
  ai_tooltip_md: '移動偵測：偵測到畫面中有移動物體後發出通報',
  ai_tooltip_threshold: '閾值越低，可以找出更多在畫面上的物件，但同時也會有降低精確度的可能性',
  ai_tooltip_motion: '靈敏度越高，對畫面中移動變化會越敏感',
  ai_tooltip_recg_fps_level: '每秒的辨識頻率，建議當物體移動的速度越快、辨識頻率設定越高',
  ai_tooltip_repeat_interval: '當事件重複發生時，間隔此設定時間後才會再次發出通報並上傳事件',
  ai_tooltip_stay_sec: '物件停留在警戒區多久後通報',
  ai_tooltip_warning_area: '最多為8個點的多邊形，物件觸碰到警戒區就會發出通報',

  /* AI Box - recognition setting */
  ai_tag: '人物標記',
  ai_note: '備註',
  ai_editor: '設定人',
  ai_expert_mode: '專家模式',
  ai_full_face_mode: '全臉模式',
  ai_half_face_mode: '半臉模式',
  ai_image_source: '影像來源',
  ai_external_cam: '外接鏡頭',
  ai_internal_cam: '內建鏡頭',
  ai_image_source_tip: '設定辨識的影像來源',
  ai_param_source: '參數來源',
  ai_global_config: '系統預設',
  ai_user_config: '設備客製',
  ai_param_source_tip: '<strong style="color: #FFE99F;">系統預設</strong>: 系統預設的辨識參數值, 不可修改。<br/><strong style="color: #FFE99F;">設備客製</strong>: 依據設備使用需求, 可自定義辨識參數值',
  ai_enable: '啟用',
  ai_enable_tip: '可 啟用/停用 人臉辨識功能',
  ai_model_country: '適用國家',
  ai_model_country_tip: '選擇欲支援車牌辨識的國家',
  ai_face_size_ratio: '臉部比例',
  ai_face_size_ratio_tip: '偵測的最小人臉大小佔畫面的百分比',
  ai_detect_mode: '比對模式',
  ai_precise_detect: '精準比對',
  ai_quick_detect: '快速比對',
  ai_detect_mode_tip: '<strong style="color: #FFE99F;">精準比對</strong>: 可輸出準確度較高的結果; <br/><strong style="color: #FFE99F;">快速比對</strong>: 可快速輸出辨識結果',
  ai_trigger_score: '觸發分數',
  ai_candidate_score: '候選分數',
  ai_global_face_trigger_score_tip:
  `<h3 style="color: #FFE99F;">分數說明</h3>
  <h4 style="margin-bottom: 4px;">嚴格 (>90)：</h4>
  <p style="margin: 0;">門禁場景，如管制區進出。</p>
  <h4 style="margin-bottom: 4px;">精準 (87-89)：</h4>
  <p style="margin: 0;">報到場景，如簽到/簽退。</p>
  <h4 style="margin-bottom: 4px;">適中 (84-86)：</h4>
  <p style="margin: 0;">簽到場景，如活動參加打卡。</p>
  <h4 style="margin-bottom: 4px;">警示 (80-83)：</h4>
  <p style="margin: 0;">集會場景，如集會監控關注對象。</p>
  <h4 style="margin-bottom: 4px;">戒備 (<80)：</h4>
  <p style="margin: 0px;">戒備場景，如遊行戒備可疑對象。</p>
  <p style="margin-top: 20px; color:#F9AEB0; font-size:16px;">分數越低，可能有較多誤報。分數越高，則需與註冊資料更為相似才可通報。</p>
  `,
  ai_global_fr_event_score_level1: '嚴格',
  ai_global_fr_event_score_level2: '精準',
  ai_global_fr_event_score_level3: '適中',
  ai_global_fr_event_score_level4: '警示',
  ai_global_fr_event_score_level5: '戒備',
  ai_global_fr_event_score_level1_tip: '門禁場景，如管制區進出',
  ai_global_fr_event_score_level2_tip: '報到場景，如簽到/簽退',
  ai_global_fr_event_score_level3_tip: '簽到場景，如活動參加打卡',
  ai_global_fr_event_score_level4_tip: '集會場景，如集會監控關注對象',
  ai_global_fr_event_score_level5_tip: '戒備場景，如遊行戒備可疑對象',
  ai_full_face_trigger_score_tip: '全臉模式下相似度的閾值. 閾值越低, 會有較多的比對結果, 但同時也會有降低精確度的可能性',
  ai_full_face_candidate_score_tip: '全臉模式下候選人物的閾值. 閾值越低, 會有較多候選人物可參考',
  ai_mask_trigger_score_tip: '半臉模式下相似度的閾值. 閾值越低, 會有較多的比對結果, 但同時也會有降低精確度的可能性',
  ai_mask_candidate_score_tip: '半臉模式下候選人物的閾值. 閾值越低, 會有較多候選人物可參考',
  ai_detect_threshold_level: '人臉偵測閾值',
  ai_detect_threshold_level_tip: '人臉偵測的閾值. 閾值越低, 可以找出更多在畫面上的人臉, 但同時也會有降低精確度的可能性',
  ai_event_post_mode: '事件上傳模式',
  ai_event_post_mode_tip: '<strong style="color: #FFE99F;">比對符合</strong>: 比對符合的事件才會被上傳; <br/><strong style="color: #FFE99F;">所有辨識</strong>: 所有的辨識結果都會被上傳',
  ai_event_match: '比對符合',
  ai_event_all: '所有辨識',
  ai_notify_filter_mode: '事件通報模式',
  ai_notify_filter_mode_tip: '<strong style="color: #FFE99F;">比對符合</strong>: 比對符合的事件才會被通報; <br/><strong style="color: #FFE99F;">全部</strong>: 所有的事件都會被通報; <br/><strong style="color: #FFE99F;">比對不符合</strong>: 比對不符合的事件才會被通報; <br/><strong style="color: #FFE99F;">不通報</strong>: 所有事件都不會被通報',
  ai_notify_match: '比對符合',
  ai_notify_all: '全部',
  ai_notify_not_match: '比對不符合',
  ai_notify_none: '不通報',
  ai_event_duration: '重複事件間隔',
  ai_noise_event_duration: '噪音事件重複間隔',
  ai_event_duration_tip: '當事件重複發生時, 間隔此設定時間後才會再次發出上傳事件',
  ai_noise_event_duration_tip: '當噪音車輛事件重複發生時, 間隔此設定時間後才會再次發出上傳事件',
  ai_repost_data_store_limit: '補傳事件保留上限',
  ai_repost_data_store_limit_tip: '無法上傳事件時, 設備保留的事件上限',
  ai_lpr_frequency: '辨識頻率',
  ai_lpr_frequency_tip: '每一秒辨識的幀數',
  ai_lpr_size_ratio: '最小車牌比例',
  ai_lpr_size_ratio_tip: '偵測的最小車牌大小佔畫面的百分比',
  ai_lpr_detect_mode: '比對模式',
  ai_noise_car_decibel_threshold: '噪音分貝閾值',
  ai_noise_car_decibel_threshold_tip: '分貝值達閾值時, 發出噪音車事件',
  ai_noise_car_event_duration_tip: '當分貝值持續達閾值時，NoiseCar 事件多久發送一次',
  ai_decibel_offset: '校正值',
  ai_decibel_offset_tip: '分貝數值補償',
  ai_confirm_count: '確認計數',
  ai_confirm_count_tip: '數值越大, 可能會較不容易輸出結果, 但輸出的結果準確性會較高',
  ai_accept_diff_num: '可接受數字差',
  ai_accept_diff_num_tip: '對辨識結果進行修正的數字數',
  ai_indent: '辨識邊界',
  ai_indent_tip: '偵測到的車牌若超出此邊界, 則不輸出辨識結果',
  ai_is_complete_mode: '完整模式',
  ai_is_complete_mode_tip: '若開啟, 只有完整的辨識結果才會被輸出. 反之, 只要有辨識結果就會被輸出',
  ai_roi: '調整辨識範圍',
  ai_roi_tip: '可拉動邊框, 調整辨識範圍',
  ai_event_unit: '筆',
  ai_low: '低',
  ai_medium: '中',
  ai_high: '高',
  ai_setting_success: '設定成功',
  ai_setting_fail: '設定失敗',
  ai_no_view_permission: '無檢視權限',
  ai_no_device_setting: '無設備參數',
  ai_no_user_setting_info: '無設備參數，載入預設值',

  /* BoviLive notify */
  bl_subscriber_checked: '已勾選 ({count})',
  bl_notice_me: '通知我',

  /* LINE notify */
  line_notify: 'LINE 通報',
  line_notify_add: '新增通報',
  line_description: '描述',
  line_group_link: '綁定 LINE 群組',
  line_link_method: '請選擇綁定方式：',
  line_link_url: '開啟網址',
  line_link_url_hint: '點選按鈕，登入 LINE 帳號，選擇群組以接收事件通知',
  line_link_qr: '掃描 QR Code',
  line_link_qr_hint: '掃描 QR Code，登入LINE帳號，選擇群組接收事件通知',
  line_link_code: '輸入權杖',
  line_link_code_hint: '登入 LINE 帳號，選擇發行權杖及欲通報群組。將發行的權杖填入下方欄位，以進行綁定',
  line_link_token: '權杖',
  line_link_token_hint: '權杖長度超過限制, 請重新輸入',
  line_group_choose: '與 LINE 連動',
  line_open_browser: '開啟瀏覽器',
  line_open_system_browser: '以系統瀏覽器開啟',
  line_linking_code: '識別碼',
  line_link: '綁定',
  line_linking_hint: '尚未綁定，請完成綁定流程',
  line_link_success: '綁定成功',
  line_link_fail: '綁定失敗',
  line_link_status: '綁定狀態',
  line_link_status_yes: '已綁定',
  line_link_status_no: '未綁定',
  line_unlink: '解除綁定',
  line_unlink_confirm: '確定解除綁定嗎？',
  line_notify_remove: '確定刪除此通知設定？',
  line_message_count: '剩餘訊息',
  line_message_reset: '重置時間',
  line_notify_test: '發送測試訊息',
  line_notify_test_success: '測試訊息發送成功',
  line_edit: '修改資料',
  line_edit_success: '設定成功',
  line_note_char_warn: '備註不得超過{count}個字元',
  line_description_char_warn: '描述不得超過{count}個字元',
  line_add_success: '新增成功',
  line_add_fail: '新增失敗',
  line_tip_title: '綁定流程',
  line_tip_url_1: '事先建立 LINE 群組，並將 LINE Notify 邀請至該群組中',
  line_tip_url_2: '點選「與 LINE 連動」',
  line_tip_url_3: '輸入帳號密碼後，點擊「登入」',
  line_tip_url_4: '選擇欲通報群組後，點擊「同意並連動」',
  line_tip_url_5: '於 LINE 群組中顯示新增相關資訊，即表示設定完成', 
  line_tip_qr_2: '手機掃描 QR Code',
  line_tip_token_1: '事先建立 LINE 群組，並將 LINE Notify 邀請至該群組中 ',
  line_tip_token_2: '登入 LINE Notify 頁面',
  line_tip_token_3: '點選「個人頁面」',
  line_tip_token_4: '點擊「發行權杖」',
  line_tip_token_5: '填寫權杖名稱及選擇欲通報群組後，點擊「發行」',
  line_tip_token_6: '複製權杖',
  line_tip_token_7: '複製權杖至 Bovia 設定頁面，點擊「 綁定」',
  line_tip_token_8: '於 LINE 群組中顯示新增相關資訊，即表示設定完成',

  /* AI Box - LPR */
  ai_lpr_tag: '車輛標記',

  /* 人辨資料庫 */
  fr_title: '人物管理',
  fr_info: '人物資訊',
  fr_filter_name: '姓名',
  fr_filter_name_hint: '王小明',
  fr_filter_tag: '標記',
  fr_filter_time: '更新時間',
  fr_filter_id: '身份識別碼',
  fr_filter_id_hint: 'Z123456789',
  // fr_filter_feature: '特徵值',
  fr_filter_enabled: '人物資訊',
  fr_filter_tooltip: '進階條件',
  frInfo_idCard_filter: '身份識別碼',
  frInfo_feature_filter: '註冊圖',
  frInfo_feature_filter_0: '全部',
  frInfo_feature_filter_1: '任一啟用',
  frInfo_feature_filter_2: '任一停用',
  frInfo_feature_filter_3: '全停用', // '無啟用'
  frInfo_feature_filter_4: '未註冊', // 沒用過
  frInfo_enabled_filter: '人物狀態',
  frInfo_enabled_filter_all: '全部',
  frInfo_enabled_filter_0: '停用',
  frInfo_enabled_filter_1: '啟用',
  fr_new_fr_tooltip: '新增人物',
  fr_db_info_tooltip: '資料庫資訊',
  fr_tag_mgr_tooltip: '標記管理',

  fr_tag_mgr_title: '標記管理',
  fr_tag_add: '新增人物標記',
  fr_tag_add_placeholder: '新增標記',
  fr_tag_duplicate_hint: '此標記名稱已存在，請重新輸入。',
  fr_tag_total: '共{total}筆標記',
  fr_tag_total_count: '共{count}/{total}筆標記',
  fr_tag_add_hint: '新增後無法刪除標記，確定新增？',

  fr_db_info_title: '資料庫資訊',
  fr_db_info_data_cnt: '{count}筆',
  fr_db_info_data_latest_sn: '最新序號：',
  fr_db_info_data_update_time: '更新時間：',
  fr_db_info_db: '資料庫',
  fr_db_info_data_fr_cnt: '人物筆數：',
  fr_db_info_feature: '特徵值',
  fr_db_info_data_used: '已使用{percent}%',
  fr_db_info_data_reg_cnt: '註冊筆數：',
  fr_db_info_data_quota: '授權配額：',
  fr_db_info_db_package: '資料庫封裝',
  fr_db_info_data_version: '版本：',

  fr_table_register: '註冊',
  fr_table_photo: '代表照', //頭像
  fr_table_name: '姓名',
  fr_table_id: '身份識別碼',
  fr_table_tag: '人物標記',
  fr_table_update_time: '更新時間',
  fr_table_create_time: '建立時間',
  fr_table_empty_hint: '請輸入查尋條件',
  fr_table_searching: '搜尋中',
  fr_table_delete_pass: '刪除成功',
  fr_table_delete_fail: '刪除失敗',

  fr_create_id_duplicate: '該身份識別碼已存在',
  fr_info_data_title: '人物資訊',
  fr_info_data_delete_confirm: '確定刪除 {name} 所有資訊嗎？',
  fr_info_data_enable: '啟用',
  fr_info_data_disable: '停用',
  fr_info_data_name: '姓名',
  fr_info_data_gender: '性別',
  fr_info_data_id: '身份識別碼',
  fr_info_data_check_id: '檢查身份識別碼',
  fr_info_data_age: '年齡',
  fr_info_data_age_edit: '生日',
  fr_info_data_tag: '標記',
  fr_info_data_note: '備註',
  fr_more: '更多',
  fr_less: '更少',
  fr_info_data_phone: '電話',
  fr_info_data_birth_place: '出生地',
  fr_info_data_city: '城市',
  fr_info_data_address: '地址',
  fr_info_data_job: '職業',
  fr_info_photo_title: '註冊圖',
  fr_info_photo_registered: '註冊',
  fr_info_photo_registered_title: '已註冊',
  fr_info_photo_unregistered_title: '未註冊',
  fr_info_photo_registered_hint: '註冊已滿',
  fr_info_photo_registering: '註冊中...',
  fr_info_photo_add: '新增照片',
  fr_info_other_title: '資料來源',
  fr_info_other_src: '來源',
  fr_info_other_src_0: '自建',
  fr_info_other_src_1: '匯入',
  fr_info_other_update_time: '更新時間',
  fr_info_other_create_time: '建立時間',
  fr_info_data_required: '必填',
  fr_info_data_phone_hint: '僅允許 數字、加號、減號、括弧',
  fr_info_data_gender_0: '其他',
  fr_info_data_gender_1: '男',
  fr_info_data_gender_2: '女',
  fr_info_data_gender_9: '未知',
  fr_info_date_fetch: '取得人物資訊',
  fr_info_data_close_hint_title: '提醒',
  fr_info_data_close_hint_msg: '您確定要放棄修改嗎？',
  fr_feature_computing: '計算中...',
  fr_feature_delete_confirm: '確定刪除此照片嗎？',
  fr_feature_1: '無效',
  fr_feature_2: '不可用',
  fr_feature_3: '較差',
  fr_feature_4: '可用',
  fr_feature_5: '最佳',
  fr_feature_invalid_hint: '無效特徵值, 無法註冊',
  fr_face_0: '全臉特徵值', // '無口罩',
  fr_face_1: '半臉特徵值', // '有口罩',
  fr_face_score: '特徵值 {score}',
  fr_face_0_score: '全臉特徵值 {score}',
  fr_face_1_score: '半臉特徵值 {score}',
  fr_action_set_avatar: '設定代表照', // || 設定頭像
  fr_action_download_photo: '下載照片',
  fr_action_delete_feature: '刪除照片',
  fr_no_birthday: "（年／月／日）",

  fr_photo_new: '新增照片',
  fr_photo_available_photo: '可用張數：',
  fr_photo_limit: '請上傳小於10MB、格式為bmp、jpeg、jpg、png的圖檔',
  fr_photo_drag: '將照片拖曳到這裡',
  fr_photo_or: '或是',
  fr_photo_browse: '瀏覽',
  fr_photo_error_format: '{fileName} 格式不支援',
  fr_photo_error_size: '{fileName} 檔案超過10M',
  fr_photo_error_count: '超過可用張數, 請刪減',

  fr_sample_ok_title: '正確範例',
  fr_sample_ok_desc1: '臉部佔比高於 70%',
  fr_sample_ok_desc2: '正臉',
  fr_sample_ok_desc3: '表情平和',
  fr_sample_ok_desc4: '光線充足',
  fr_sample_ok_desc5: '清晰的彩色照片',
  fr_sample_ok_desc6: '露出眉毛',
  fr_sample_ok_desc7: '照片中僅有一張臉孔',
  fr_sample_fail_title: '錯誤範例',
  fr_sample_fail_desc1: '表情過於豐富',
  fr_sample_fail_desc2: '不宜使用側臉',
  fr_sample_fail_desc3: '不可包含多張人臉',

  fr_create: '新增人物',
  fr_create_pass: '新增人物資訊成功',
  fr_create_fail: '新增人物資訊失敗',
  fr_edit: '編輯人物資訊',
  fr_edit_pass: '編輯人物資訊成功',
  fr_edit_fail: '編輯人物資訊失敗',
  fr_create_fail1: '請輸入 姓名 或 身份識別碼',
  fr_feature_upload: '上傳註冊圖',
  fr_feature_delete: '刪除註冊圖',
  fr_feature_enable: '啟用註冊圖',
  fr_feature_enable_hint: '請先啟用此人物',
  fr_feature_disable: '停用註冊圖',

  // 物件辨識
  or_class_1: '人',
  or_class_2: '汽車',
  or_class_3: '二輪車',
  or_class_4: '卡車',
  or_class_5: '公車',
  or_cloud: '雲端',
  or_full_view: '全畫面',

  // 縮時攝影
  time_lapse_task: '任務設定',
  time_lapse_export: '任務清單',

  // Dashboard-Device Param ===
  device_param_title: '設備參數',
  device_param_aicam_save_title: '設定 AiCam 參數',
  device_param_patrol_save_title: '設定 Patrol 參數',
  device_param_bovicast_save_title: '設定 Bovicast 參數',
  // 設備狀態 ---
  device_param_status: '設備狀態',
  // 設備狀態>連線狀態
  // 設備狀態>頻道狀態
  // 設備狀態>流量
  // 設備狀態>名稱
  // 設備狀態>感測器
  // 設備狀態>其他
  // 影像設定 ---
  device_param_video: '影像設定',
  device_param_video_quality: '品質 ({codec})',
  device_param_video_quality_w: '寬度',
  device_param_video_quality_h: '長度',
  device_param_video_quality_framerate: '幀率(fps)',
  device_param_video_quality_bitrate: '位元率(Kbps)',
  // 影像設定>頻道
  device_param_video_channel: '頻道',
  device_param_video_channel_image_source_type: '影像來源',
  device_param_video_channel_image_source_type_0: '外接',
  device_param_video_channel_image_source_type_1: '內建（後鏡頭）',
  device_param_video_channel_image_source_type_2: '內建（前鏡頭）',
  device_param_video_channel_image_source_type_3: 'UVC',
  device_param_video_channel_image_source_type_4: '自動',
  device_param_video_channel_stream_url: '影像網址',
  // 影像設定>發布
  device_param_video_publish: '發布',
  device_param_video_channel_index: '發布頻道',
  device_param_video_auto_start: '自動發布',
  device_param_video_audio_mute: '靜音',
  device_param_video_code_codec: '影像編碼',
  device_param_video_osd: 'OSD',
  device_param_video_osd_date_enabled: 'OSD-時間',
  device_param_video_osd_imu_enabled: 'OSD-慣性測量單元', // Inertial measurement unit
  device_param_video_osd_temp_enabled: 'OSD-溫度計數值',
  device_param_video_osd_font_size_index: 'OSD-字型大小',
  device_param_video_osd_decibel_enabled: 'OSD-分貝計數值', // BFV only
  device_param_video_osd_signal_info_enabled: 'OSD-訊號強度',
  device_param_video_osd_battery_level_percentage_enabled: 'OSD-電池電量（百分比）',
  device_param_video_osd_battery_voltage_enabled: 'OSD-電池電量（電壓值）',
  device_param_video_customize_quality: '自定品質',
  device_param_video_bitrate_mode: '位元控制率模式',
  // 影像設定>錄影
  device_param_video_record: '錄影',
  device_param_video_record_duration: '錄影檔案長度',
  // 參數設定 ---
  device_param_setting: '參數設定',
  device_param_setting_charging_control: '充電狀態連動控制',
  // 參數設定>系統
  device_param_setting_system: '系統',
  device_param_setting_system_battery_level_percentage_method: '電池電量計算方式',
  device_param_setting_system_battery_level_percentage_method_dc: 'DC',
  device_param_setting_system_battery_level_percentage_method_12v: '12V',
  device_param_setting_system_battery_level_percentage_method_24v: '24V',
  // 參數設定>介面
  device_param_setting_interface: '介面',
  device_param_setting_btn_long_press_time: '按鈕長按觸發時間',
  // 參數設定>語音通話
  device_param_setting_voice_call: '語音通話',
  device_param_setting_auto_answer: '自動接聽',
  // 參數設定>公告
  device_param_setting_announcement_notify_type: '語音播報',
  device_param_setting_announcement_notify_type_0: '無',
  device_param_setting_announcement_notify_type_1: '語音',
  device_param_setting_announcement_notify_type_2: '提示音',
  device_param_setting_announcement_display_type: '顯示',
  device_param_setting_announcement_display_type_0: '不顯示',
  device_param_setting_announcement_display_type_1: '顯示一次',
  device_param_setting_announcement_display_type_2: '永遠',
  // 參數設定>事件
  device_param_setting_event: '事件',
  device_param_setting_event_imu_front_back_threshold: '傾斜角度（前後）閾值',
  device_param_setting_event_imu_right_left_threshold: '傾斜角度（左右）閾值',
  device_param_setting_event_temperature_threshold: '溫度感測器閾值',
  device_param_setting_notify_in_app: 'App事件通知',
  device_param_setting_notify_in_app_0: '永不',
  device_param_setting_notify_in_app_1: '總是',
  device_param_setting_notify_in_app_2: '畫面隱藏時',
  device_param_setting_notify_in_app_3: '畫面顯示時',
  device_param_setting_wake_up_screen: '事件觸發畫面',
  device_param_setting_audio_notify_type: '提示音',
  device_param_potral_setting_audio_notify_type_0: '無',
  device_param_potral_setting_audio_notify_type_1: '警示音',
  device_param_potral_setting_audio_notify_type_2: '語音提示',
  device_param_bovicast_setting_audio_notify_type_0: '無',
  device_param_bovicast_setting_audio_notify_type_1: '提示音',
  device_param_setting_announce_mission_note: '播報任務推播訊息',
  device_param_setting_announce_tag_note: '播報車輛備註訊息',
  device_param_setting_chase_event_popup_time: '圍捕視窗自動關閉時間',
  device_param_setting_jpg_quality: 'JPG畫質',
  device_param_setting_crop_jpg_quality: '截圖JPG畫質',
  device_param_setting_enable_vibration: '振動提示',
  // 參數設定>診斷
  device_param_setting_diagnose: '診斷',
  device_param_setting_min_audio_volume: '最小音量提醒',
  // 參數設定>儲存裝置
  device_param_setting_disk: '儲存裝置',
  device_param_setting_disk_threshold: '保留空間',
  device_param_setting_disk_cleanup_type: '刪檔順序',
  device_param_setting_disk_cleanup_type_0: '禁止刪檔',
  device_param_setting_disk_cleanup_type_1: '錄影檔優先刪除',
  device_param_setting_disk_cleanup_type_2: '辨識事件優先刪除',
  // 參數設定>定位
  device_param_setting_locate: '定位',
  device_param_setting_post_duration: '最小上傳間隔',
  // 參數設定>AI
  // device_param_setting_ai: 'AI',
  // device_param_setting_ai_hidden: '沉默模式',
  // device_param_setting_ai_hidden_0: 'AI關閉',
  // device_param_setting_ai_hidden_1: 'AI開啟-一般模式',
  // device_param_setting_ai_hidden_2: 'AI開啟-沉默模式',
  // 參數設定>工作日誌
  device_param_setting_log: '工作日誌',
  device_param_setting_log_keep_days: '保留天數',
  // 參數設定>人物
  device_param_setting_fr: '人物',
  // 參數設定>TW車牌
  device_param_setting_lprTw: 'TW車牌',
  // 參數設定>JP車牌
  device_param_setting_lprJp: 'JP車牌',
  // 參數設定>VN車牌
  device_param_setting_lprVn: 'VN車牌',
  // 參數設定>物件
  device_param_setting_or: '物件',
  // save
  device_param_save_header: '修改設備參數',
  device_param_save_title: '您確定要修改參數嗎？',

  // 日誌 =====================================================================
  log_access: '存取日誌',
  log_device: '設備日誌',
  log_resource: '資源日誌',
  log_account_type: '帳號',
  log_account_type_user: '使用者',
  log_account_type_device: '設備',
  log_search_placeholder: '搜尋帳號',
  log_search_placeholder_user: '搜尋使用者',
  log_search_placeholder_device: '搜尋設備',
  log_timestamp: '操作時間',
  log_operator: '操作人',
  log_operator_group: '操作人群組',
  log_ip: 'IP 位置',
  log_content: '內容',
  log_target: '目標',
  log_device_account: '設備',
  log_device_timestamp: '時間區間',
  log_device_account_hint: '請選擇設備',
  log_device_tab_device: '設備',
  log_device_tab_filename: '檔名',
  log_device_tab_date: '日期',
  log_device_download_fail: '下載失敗',

  // codebook ----------------------------------------------------------------
  log_codebook_0: '登入',
  log_codebook_1: '登入成功',
  log_codebook_2: '登入失敗',

  // 群組 ---
  log_codebook_100: '群組',
  log_codebook_101: '新增群組',
  log_codebook_102: '編輯群組',
  log_codebook_103: '刪除群組',
  // log_codebook_104: 'create_group_license',
  // log_codebook_105: 'edit_group_license',
  // log_codebook_106: 'delete_group_license',

  // 使用者 ---
  log_codebook_200: '使用者',
  log_codebook_201: '新增使用者',
  log_codebook_202: '編輯使用者',
  log_codebook_203: '刪除使用者',
  // log_codebook_204: 'create_user_license',
  // log_codebook_205: 'edit_user_license',
  // log_codebook_206: 'delete_user_license',
  log_codebook_207: '驗證使用者電子郵件',
  log_codebook_208: '解鎖帳號',
  log_codebook_209: '鎖定帳號',

  // 設備 ---
  log_codebook_300: '設備',
  log_codebook_301: '新增設備',
  log_codebook_302: '編輯設備',
  log_codebook_303: '刪除設備',

  // 伺服器 ---
  log_codebook_400: '伺服器',
  log_codebook_401: '新增伺服器',
  log_codebook_402: '編輯伺服器',
  log_codebook_403: '刪除伺服器',

  // 發布 ---
  log_codebook_500: '發布',
  log_codebook_501: '發布成功',
  log_codebook_502: '發布失敗',

  // 收看 ---
  log_codebook_600: '收看',
  log_codebook_601: '收看成功',
  log_codebook_602: '收看失敗',

  // 歷史影像 ---
  log_codebook_700: '歷史影像',
  log_codebook_701: '新增影像',
  log_codebook_702: '編輯影像',
  log_codebook_703: '刪除影像',
  log_codebook_704: '回放影像成功',
  log_codebook_705: '回放影像失敗',
  log_codebook_706: '下載影像成功',
  log_codebook_707: '下載影像失敗',

  // // [legacy] Jager Eye config ---
  // log_codebook_800: 'Jager Eye',
  // log_codebook_801: 'create_jager_eye_config',
  // log_codebook_802: 'edit_jager_eye_config',
  // log_codebook_803: 'delete_jager_eye_config',
  // log_codebook_804: 'enable_jager_eye_config',
  // log_codebook_805: 'disable_jager_eye_config',
  // log_codebook_806: 'edit_jager_eye_event',
  // log_codebook_807: 'delete_jager_eye_evnet',

  // 人辨 ---
  log_codebook_900: '人物辨識',
  log_codebook_901: '新增人物辨識設定',
  log_codebook_902: '編輯人物辨識設定',
  log_codebook_903: '刪除人物辨識設定',

  // 設備控制 ---
  log_codebook_1000: '設備控制',
  log_codebook_1001: '設備控制成功',
  log_codebook_1002: '設備控制失敗',

  // 遠端控制 ---
  log_codebook_1100: '遠端控制',
  log_codebook_1101: '遠端控制成功',
  log_codebook_1102: '遠端控制失敗',

  // 車牌辨識 ---
  log_codebook_1200: '車牌辨識',
  log_codebook_1201: '新增車牌辨識設定',
  log_codebook_1202: '編輯車牌辨識設定',
  log_codebook_1203: '刪除車牌辨識設定',
  log_codebook_1204: '新增辨識車輛',
  log_codebook_1205: '編輯辨識車輛',
  log_codebook_1206: '刪除辨識車輛',
  log_codebook_1207: '編輯車牌辨識快照',
  log_codebook_1208: '刪除車牌辨識快照',
  log_codebook_1209: '編輯車牌辨識事件',
  log_codebook_1210: '刪除車牌辨識事件',
  log_codebook_1211: '新增車牌辨識標記',
  log_codebook_1212: '編輯車牌辨識標記',
  log_codebook_1213: '刪除車牌辨識標記',

  // 角色 ---
  log_codebook_1300: '角色',
  log_codebook_1301: '新增使用者角色',
  log_codebook_1302: '編輯使用者角色',
  log_codebook_1303: '刪除使用者角色',

  // 物件辨識事件 ---
  log_codebook_1400: '物件辨識',
  log_codebook_1401: '編輯物件辨識事件',
  log_codebook_1402: '刪除物件辨識事件',

  // 伺服器維護中
  construction_msg: '伺服器維護中',

  // 憑證
  system_cert_ca_title: '對象',
  system_cert_ca: '簽發對象',
  system_cert_time: '時間',
  system_cert_enable_time: '啟用時間',
  system_cert_expired_time: '到期時間',
  system_cert_update_time: '更新時間',
  system_cert_timestamp: '時間戳',
  system_cert_fingerprint: '指紋',
  system_cert_fingerprint_encryption: '加密',
  system_cert_certificate: '憑證',
  system_cert_public_key: '公開金鑰',
  system_cert_input_code: '請輸入驗證碼以更新：',
  system_cert_file: '憑證檔',
  system_cert_private_key_file: '私鑰檔',
  system_cert_state_check: '憑證確認',
  system_cert_state_checking: '憑證確認中',
  system_cert_state_checked: '憑證確認完成',
  system_cert_state_check_failed: '憑證確認失敗',
  system_cert_state_update_title: '憑證更新',
  system_cert_state_update: '憑證更新',
  system_cert_state_updating: '憑證更新中',
  system_cert_state_updated: '憑證更新完成',
  system_cert_state_update_failed: '憑證更新失敗',
  system_cert_validate_code_error: '驗證碼錯誤',

  //--------------------------------------------
  // 停車場管理
  //--------------------------------------------
  parking_count: '停車場計數',
  parking_filter_factory: '廠區',
  parking_filter_parking: '停車場',
  parking_tab_factory: '廠區',
  parking_tab_parking: '停車場',
  parking_tab_resetNum: '設定車輛數',
  parking_tab_resetTime: '設定時間',
  parking_tab_nowNum: '即時車輛數',
  parking_tab_updateTime: '更新時間',
  parking_list_error: '取得停車場資訊失敗',
  parking_list_edit: '修改車輛數',
}

// console.log(`tw:`, Object.keys(tw).length)

export default tw
