import { getDeepKeys } from '@/utils/lib'
import { mergeDefaults } from './configOr'

export const frDeviceIds = [ 2000 ]

export const frConfig = {
  paramSource: {
    title: '$t_ai_param_source'/*參數來源*/,
    default: 1,
    options: [
      { label: '$t_ai_global_config'/*系統預設*/, value: 1 },
      { label: '$t_ai_user_config'/*設備客製*/, value: 0 },
    ],
    tooltip: '$t_ai_param_source_tip',
  },
  isEnabled: {
    title: '$t_ai_enable'/*啟用*/,
    default: 1,
    options: [
      { label: '$t_on'/*開啟*/, value: 1, icon: 'checked-green.svg', hintIcon: 'checked-gray.svg' },
      { label: '$t_off'/*關閉*/, value: 0, icon: 'Error.svg', hintIcon: 'Error-gray.svg' },
    ],
    tooltip: '$t_ai_enable_tip',
  },
  faceSizeRatio: {
    title: '$t_ai_face_size_ratio'/*'臉部比例*/,
    default: 10,
    min: 5,
    max: 50,
    unit: '%',
    tooltip: '$t_ai_face_size_ratio_tip',
  },
  detectMode: {
    title: '$t_ai_detect_mode'/*比對模式*/,
    default: 1,
    options: [
      { label: '$t_ai_precise_detect'/*精準比對*/, value: 1 },
      { label: '$t_ai_quick_detect'/*快速比對*/, value: 0 },
    ],
    tooltip: '$t_ai_detect_mode_tip'/*比對模式的說明*/,
  },
  globalEventScore: {
    title: '$t_ai_trigger_score'/*觸發分數*/,
    default: 85,
    min: 75,
    max: 95,
    unit: '',
    tooltip: '$t_ai_global_face_trigger_score_tip',
  },
  eventScore: {
    title: '$t_ai_trigger_score'/*觸發分數*/,
    default: 85,
    min: 75,
    max: 95,
    unit: '',
    tooltip: '$t_ai_full_face_trigger_score_tip',
  },
  candidateScore: {
    title: '$t_ai_candidate_score'/*候選分數*/,
    default: 83,
    min: 75,
    max: 95,
    unit: '',
    tooltip: '$t_ai_full_face_candidate_score_tip',
  },
  eventScoreWithMask: {
    title: '$t_ai_trigger_score'/*觸發分數*/,
    default: 80,
    min: 75,
    max: 95,
    unit: '',
    tooltip: '$t_ai_mask_trigger_score_tip',
  },
  candidateScoreWithMask: {
    title: '$t_ai_candidate_score'/*候選分數*/,
    default: 78,
    min: 75,
    max: 95,
    unit: '',
    tooltip: '$t_ai_mask_candidate_score_tip',
  },
  detectThresholdLevel: {
    title: '$t_ai_detect_threshold_level'/*人臉偵測閥值*/,
    default: 2,
    min: 0,
    max: 2,
    label: {
      0: '$t_ai_low'/*低*/,
      1: '$t_ai_medium'/*中*/,
      2: '$t_ai_high'/*高*/,
    },  
    unit: '',
    tooltip: '$t_ai_detect_threshold_level_tip',
  },
  eventPostMode: {
    title: '$t_ai_event_post_mode'/*事件上傳模式*/,
    default: 0,
    options: [
      { label: '$t_ai_event_match'/*比對符合*/, value: 0 },
      { label: '$t_ai_event_all'/*所有辨識*/, value: 1 },
    ],
    tooltip: '$t_ai_event_post_mode_tip',
  },
  notifyFilterMode: {
    title: '$t_ai_notify_filter_mode'/*事件通報模式*/,
    default: 2,
    options: [
      { label: '$t_ai_notify_match'/*比對符合*/, value: 2 },
      { label: '$t_ai_notify_all'/*全部*/, value: 3 },
      { label: '$t_ai_notify_not_match'/*比對不符合*/, value: 1 },
      { label: '$t_ai_notify_none'/*不通報*/, value: 0 },
    ],
    tooltip: '$t_ai_notify_filter_mode_tip',
  },
  eventDuration: {
    title: '$t_ai_event_duration'/*重複事件間隔*/,
    default: 5,
    min: 1,
    max: 600,
    unit: '$t_sec'/*秒*/,
    tooltip: '$t_ai_event_duration_tip',
  },
  repostDataStoreLimit: {
    title: '$t_ai_repost_data_store_limit'/*補傳事件筆數*/,
    default: 1000,
    min: 50,
    max: 1000,
    step: 10,
    unit: '$t_ai_event_unit'/*筆*/,
    tooltip: '$t_ai_repost_data_store_limit_tip',
  },  
}

const localChannel = {  
  fr: {
    roi: { 
      x1: 0, 
      x2: 0, 
      y1: 0, 
      y2: 0
    },
    isEnabled: 1,
    detectMode: 1,
    eventScore: 85,
    faceSizeRatio: 10,
    candidateScore: 83,
    eventScoreWithMask: 80,
    detectThresholdLevel: 2,
    candidateScoreWithMask: 78
  }
}

const localDeviceConfig = {
  fr: {
    tagFilter: [],
    eventDuration: 5,
    eventPostMode: 0,
    repostDataStoreLimit: 1000
  }
}

export const setFrDefaultValue = (setting, refSetting = {}) => {
  try {
    const origKeys = getDeepKeys(setting)

    // check channel fr data
    setting.channel.forEach((channel, index) => {
      mergeDefaults(channel.fr, localChannel.fr, refSetting?.channel?.[index]?.fr)
    })

    // check deviceConfig
    const deviceConfig = setting.deviceConfig
    mergeDefaults(deviceConfig.fr, localDeviceConfig.fr, refSetting?.deviceConfig?.fr)

    const newKeys = getDeepKeys(setting)
    const missingTags = newKeys.filter(key => !origKeys.includes(key))

    return missingTags
  } catch (err) {
    console.error('setFrDefaultValue error: ', err)
  }
}