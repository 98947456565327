export const initAiboxTaskOr = {
  id: -99,  // temporary number: -99
  ai: 'or',
  aiboxId: '',
  aiboxName: '',
  config: {
    area1: [],
    area2: [],
    area3: [],
    md: {
      motionLevel: 2,     // 0-3 0未啟動 1 2(default) 3 
      recgFpsLevel: 3,    // 0-5, 0為ASAP , fps value: 3/5/7/10/15, default: 3
      repeatInterval: 5,  // 1~60 (mins), default: 5
      staySec: 5,         // 0-60 (s), default: 5
    },
    mode: 1, // 1-2, 1: or, 2: motion
    or: {
      personLevel: 3,     // 0-5, 0 為 disable, default: 3
      carLevel: 3,        // 0-5, 0 為 disable, default: 3
      bikeLevel: 3,       // 0-5, 0 為 disable, default: 3
      truckLevel: 3,      // 0-5, 0 為 disable, default: 3
      busLevel: 3,        // 0-5, 0 為 disable, default: 3
      recgFpsLevel: 3,    // 0-5, 0為ASAP , fps value: 3/5/7/10/15, default: 3
      staySec: 5,         // 0-60 (s), default: 5
      repeatInterval: 5,  // 1~60 (mins), default: 5
    },
    roi: {
      x1: 0,
      y1: 0,
      x2: 1,
      y2: 1,
    },
    version: '1.0'
  },
  notice: '',
  sourceId: '',
  status: '',
  subscribers: [],
}

export const initAiboxTaskLpr = {
  id: -99,  // temporary number: -99
  ai: 'lpr',
  aiboxId: '',
  config: {
    aiModelType: 'tw',
    lprTw: {
      acceptDiffNum: 1,
      confirmCount: 1,
      detectMode: 1,
      eventDuration: 210,
      eventPostMode: 1,
      indent: 0.01,
      isCompleteMode: 0,
      lprFrequency: 30,
      lprSizeRatio: 5,
      repostDataStoreLimit: 1000,
      roi: {
        x1: 0,
        y1: 0,
        x2: 1,
        y2: 1,
      },
      tagFilter: []
    },
    lprJp: {
      acceptDiffNum: 2,
      confirmCount: 1,
      detectMode: 1,
      eventDuration: 210,
      eventPostMode: 1,
      indent: 0.01,
      isCompleteMode: 0,
      lprFrequency: 30,
      lprSizeRatio: 5,
      repostDataStoreLimit: 1000,
      roi: {
        x1: 0,
        y1: 0,
        x2: 1,
        y2: 1,
      },
      tagFilter: []
    },
    lprVn: {
      acceptDiffNum: 1,
      confirmCount: 1,
      detectMode: 1,
      eventDuration: 210,
      eventPostMode: 1,
      indent: 0.01,
      isCompleteMode: 0,
      lprFrequency: 30,
      lprSizeRatio: 5,
      repostDataStoreLimit: 1000,
      roi: {
        x1: 0,
        y1: 0,
        x2: 1,
        y2: 1,
      },
      tagFilter: []
    },
    version: '1.0'
  },
  notice: '',
  notifyFilterMode: 0,
  sourceId: '',
  subscribers: [],
}