<template>
  <div id="app" :lang="userFlatObj.language">
    <div ref="wrapWatermark" class="watermark-wrap">
      <div class="watermark" v-for="(item, index) in watermarkCount" :key="`watermark-${index}`">
        {{ watermark }}
      </div>
    </div>
    <!--    <HomePage />-->
    <transition>
      <router-view />
    </transition>

    <portal-target name="calling-modal"></portal-target>
    <portal-target name="stop-call-modal"></portal-target>
    <portal-target name="user-info-modal"></portal-target>
    <portal-target name="editpsd-modal"></portal-target>
    <portal-target name="two-factor-modal"></portal-target>
    <portal-target name="logout-modal"></portal-target>
    <portal-target name="allow-audio-modal"></portal-target>
    <portal-target name="history-event-modal"></portal-target>
    <portal-target name="export-info-modal"></portal-target>
    <portal-target :name="accountPortal"></portal-target>
    <portal-target name="ai-box-modal"></portal-target>
    <portal-target name="ai-box-portal"></portal-target>
    <AccountInfoModal v-if="showAccountInfoModal" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    AccountInfoModal: () => import('@/components/LeftBar/AccountInfoModal.vue')
  },
  data() {
    return {
      watermarkCount: 0,
      timeoutId: null,
      lastTime: new Date().getTime()
    }
  },
  computed: {
    ...mapState('userinfo', ['accountPortal']),
    ...mapState([
      'staff',
      'userFlatObj',
      'notAutomaticLogout',
      'idleTimeout',
      'systemName',
      'bgWatermark',
      'favicon',
      'isGetWebPreferences',
      'showAccountInfoModal'
    ]),
    idleTimeoutMs() {
      return this.idleTimeout * 60 * 1000
    },
    isIdleLogout() {
      return !this.notAutomaticLogout && this.idleTimeout > 0
    },
    watermark() {
      // 群組名稱 (userAccount)
      return `${ this.userFlatObj.groupName } (${ this.userFlatObj.id })`
    },
    hasWatermark() {
      // 若是staff, 不顯示浮水印
      if (this.staff === 1) return false
      return this.$route.path !== '/login' && (this.bgWatermark || this.userFlatObj.watermarkEnabled)
    }
  },
  watch: {
    isIdleLogout() {
      if (this.isIdleLogout) this.startIdleTimer()
      else this.clearIdleTimer()
    },
    'userFlatObj.language'() {
      document.documentElement.lang = this.userFlatObj.language
    },
    hasWatermark() {
      this.calWatermarkCount()
    },
    favicon(nVal, oVal) {
      // 處理即時更新 favicon
      if (nVal !== oVal) {
        this.changeFavicon()
      }
    }
  },
  created() {
    this.getWebPreferences()
  },
  mounted() {
    if (this.favicon && this.isGetWebPreferences) this.changeFavicon()
    if (this.systemName) document.title = this.systemName
    this.startIdleTimer()
    document.documentElement.lang = this.userFlatObj.language
    this.calWatermarkCount()
    window.addEventListener('resize', this.calWatermarkCount)
  },
  beforeDestroy() {
    this.clearIdleTimer()
    window.removeEventListener('resize', this.calWatermarkCount)
  },
  methods: {
    ...mapMutations(['updateWebAnnouncements']),
    ...mapActions(['getWebPreferences', 'logout']),
    calWatermarkCount() {
      if (!this.hasWatermark) {
        this.watermarkCount = 0
        return
      } 

      const wrap = this.$refs.wrapWatermark
      if (wrap) {
        const wrapW = wrap.offsetWidth * 1.2
        const wrapH = wrap.offsetHeight * 1.2
        const watermarkW = 120
        const watermarkH = 120
        const cols = Math.floor(wrapW / watermarkW)
        const rows = Math.floor(wrapH / watermarkH)
        this.watermarkCount = cols * rows
      } else {
        this.watermarkCount = 300 // 預設 300 個
      }
    },
    changeFavicon() {
      let link = document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.getElementByTagName('head')[0].appendChild(link)
      }
      link.href = this.favicon || require(`@/../public/favicon.png`) // 處理即時更新 favicon
    },
    startIdleTimer() {
      document.addEventListener('mousedown', this.setActiveTime, false)
      this.checkTimeout()
    },
    clearIdleTimer() {
      document.removeEventListener('mousedown', this.setActiveTime, false)
      clearTimeout(this.timeoutId)
      this.timeoutId = null
    },
    setActiveTime() {
      this.lastTime = new Date().getTime()
    },
    checkTimeout() {
      // if (!this.notAutomaticLogout && this.idleTimeout > 0 &&
      //   (new Date().getTime() - this.lastTime) > this.idleTimeoutMs) {
      //   if (this.$route.path !== '/login') {
      //     this.logout()
      //     this.$router.push('/login')
      //   }
      //   this.lastTime = new Date().getTime()
      // }

      // 只有 dashboard 可以保持登入, 其他頁面要維持自動登出
      const now = Date.now()
      if (this.idleTimeout > 0) {
        const elapsedTime = now - this.lastTime
        // console.log(`[App.checkTimeout] ${elapsedTime}/${this.idleTimeoutMs}`)
        if (this.$route.path.includes('dashboard')) {
          if (!this.notAutomaticLogout && elapsedTime > this.idleTimeoutMs) {
            // this.logout()
            this.$router.push('/login')
          }
        } else if (!this.$route.path.includes('login')) {
          if (elapsedTime > this.idleTimeoutMs) {
            // this.logout()
            this.$router.push('/login')
          }
        }
      }

      this.timeoutId = setTimeout(() => {
        this.checkTimeout()
      }, 30000) // 每 30 秒, 檢查一次登出
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';

// html, body {
//   margin: 0;
//   padding: 0;
//   font-size: font-size(null);
//   font-weight: 300;
//   overflow: hidden;
// }

// @media (max-width: 1400px) {
//   html, body {
//     font-size: font-size(1400); // calc(12px + 0.4vw);
//   }
// }

#app {
  position: relative;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease-in;
}
</style>

<style lang="scss" scoped>
.watermark-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 120%;
  height: 120%;
  z-index: 10000;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 100px;
  transform: rotate(-30deg) translate(0%, -40%);
  pointer-events: none;

  .watermark {
    font-size: px2rem(16);
    line-height: px2rem(24);
    color: #ffffff33;
    text-shadow: 0px 0px 2px #00000033;
  }
}
</style>
